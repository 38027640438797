<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click.prevent>{{ $t('dashboard') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{ $t('users') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('users') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_header">
            <button class="btn btn-danger-light" @click="showAddModal()">{{ $t('add_user') }}</button>

            <!--                  <form class="form-inline my-2 my-lg-0" dir="ltr" @submit.prevent="getAllUsers()">-->
            <!--                    <input class="form-control" type="search" :placeholder="$t('search')" v-model="query" aria-label="Search"/>-->
            <!--                    <button class="btn my-2 my-sm-0" type="submit">-->
            <!--                      <i class="ti-search"></i>-->
            <!--                    </button>-->
            <!--                  </form>-->
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left ">#</th>
                  <th class="text-left ">{{ $t('name') }}</th>
                  <th class="text-left ">{{ $t('username') }}</th>
                  <th class="text-left ">{{ $t('email') }}</th>
                  <th class="text-left ">{{ $t('phone') }}</th>
                  <th class="text-left ">{{ $t('role') }}</th>
                  <th class="text-left ">{{ $t('adding_date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user , index) in users" :key="index">
                  <td>{{ offset + index + 1 }}</td>
                  <td>{{ user?.student?.name || user?.instructor?.name_ar || user?.instructor?.name_en || '' }}</td>
                  <td>{{ user.username }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user?.student?.mobile || user?.instructor?.mobile || '' }}</td>
                  <td>{{ user.roles.name_ar }}</td>
                  <td>{{ parseDate(user.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="users.length==0">{{ $t('no_data') }}</p>
            </div>

            <div class="text-center" v-if="totalCount > limit">
              <paginate
                  :page-count="Math.ceil(totalCount / limit)"
                  :page-range="3"
                  :click-handler="handlePaginate"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">
              </paginate>
            </div>

          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addUserModalRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ 'إضافة مستخدم' }}</h4>
      <div class="row text-left">
        <div class="col-sm-6 col-12 mt-2">
          <label class="font-weight-bold">{{ $t('role') }}</label>
          <select v-validate="'required'" data-vv-name="role" v-model="newUser.role" class="form-control">
            <option v-for="(role , index) in roles" :key="index" :value="role.name_en">{{ $helper.getTitleValueLang(role) }}</option>
          </select>
        </div>

        <div class="col-sm-6 col-12 mt-2">
          <label class="font-weight-bold">{{ $t('username') }}</label>
          <input type="text" v-validate="'required'" v-model="newUser.username" class="form-control" :placeholder="$t('username')" data-vv-name="username">
        </div>

        <div class="col-sm-6 col-12 mt-2">
          <label class="font-weight-bold">{{ $t('email') }}</label>
          <input type="email" v-validate="'required|email'" v-model="newUser.email" class="form-control" :placeholder="$t('email')" data-vv-name="email">
        </div>

        <div class="col-sm-6 col-12 mt-2">
          <label class="font-weight-bold">{{ $t('password') }}</label>
          <input type="text" v-validate="'required|min:6'" v-model="newUser.password" class="form-control" :placeholder="$t('password')" data-vv-name="password">
        </div>

        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && (newUser.role.toLowerCase() === 'student' || newUser.role.toLowerCase() === 'instructor')">
          <label class="font-weight-bold">{{ $t('phone') }}</label>
          <input type="text" v-validate="'required'" v-model="newUser.mobile" class="form-control" :placeholder="$t('phone')" data-vv-name="phone">
        </div>

        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && (newUser.role.toLowerCase() === 'student' || newUser.role.toLowerCase() === 'instructor')">
          <label class="font-weight-bold">{{ $t('name') }}</label>
          <input type="text" v-validate="'required'" v-model="newUser.name" class="form-control" :placeholder="$t('name')" data-vv-name="name">
        </div>

        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && newUser.role.toLowerCase() === 'student'">
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('university') }}</label>

            <multiselect
                :select-label="$t('multiselect_choose')" v-validate="'required'" data-vv-name="university"
                :options="universities" :custom-label="$helper.getTitleValueLang"
                track-by="id" v-model="selectedUniversity" @input="selectedUniversity && setFaculties(selectedUniversity.id)"
                :placeholder="$t('search')"></multiselect>
          </div>
        </div>
        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && newUser.role.toLowerCase() === 'student'">
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('faculty') }}</label>

            <multiselect
                :select-label="$t('multiselect_choose')" v-validate="'required'" data-vv-name="faculty"
                :options="faculties" :custom-label="$helper.getTitleValueLang"
                track-by="id" v-model="selectedFaculty" @input="selectedFaculty && setDepartments(selectedFaculty.id)"
                :placeholder="$t('search')"></multiselect>
          </div>
        </div>
        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && newUser.role.toLowerCase() === 'student'">
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('section') }}</label>

            <multiselect
                :select-label="$t('multiselect_choose')" v-validate="'required'" data-vv-name="department"
                :options="departments" :custom-label="$helper.getTitleValueLang"
                track-by="id" v-model="selectedDepartment" @input="selectedDepartment && setAcademicYears(selectedDepartment.id)"
                :placeholder="$t('search')"></multiselect>
          </div>
        </div>
        <div class="col-sm-6 col-12 mt-2" v-if="newUser.role && newUser.role.toLowerCase() === 'student'">
          <div class="form-group">
            <label class="font-weight-bold">{{ $t('academicyear') }}</label>

            <multiselect
                :select-label="$t('multiselect_choose')" v-validate="'required'" data-vv-name="academicYear"
                :options="academicYears" :custom-label="$helper.getTitleValueLang"
                track-by="id" v-model="selectedAcademicYear"
                :placeholder="$t('search')"></multiselect>
          </div>
        </div>

        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addNewUser()">
            {{ $t('add') }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import moment from "moment/moment";

export default {
  name: "All",
  components: { SweetModal },
  data() {
    return {
      newUser: {
        username: '',
        email: '',
        password: '',
        role: '',
        name: '',
        mobile: '',
      },
      users: [],
      roles: [],
      limit: 20,
      offset: 0,
      totalCount: 0,
      error: null,
      universities: [],
      selectedUniversity: null,
      faculties: [],
      selectedFaculty: null,
      departments: [],
      selectedDepartment: null,
      academicYears: [],
      selectedAcademicYear: null,
    }
  },
  mounted() {
    this.getAllUsers();
    this.getAllRoles();
    this.getAllUniversities();
  },
  methods: {
    handlePaginate(pageNum) {
      this.offset = (pageNum - 1) * this.limit;
      this.getAllUsers();
    },
    parseDate(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY  HH:mm");
      }
      return ' - ';
    },
    prepareFilters() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
    getAllUsers() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();

      this.$store.dispatch('moduleUser/listUsers', filters).then((response) => {
        vm.users = response.data.data.rows || [];
        vm.totalCount = response.data.data.count || 0;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllRoles() {
      let vm = this;
      vm.$helper.showLoader();

      this.$store.dispatch('moduleUser/listAllRoles').then((response) => {
        vm.roles = response.data.data || [];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    getAllUniversities() {
      let vm = this;
      vm.$helper.showLoader();
      const filters = {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: ''
      };
      this.$store.dispatch('moduleUniversity/getAllUniversities', filters).then(() => {
        vm.universities = this.$store.getters['moduleUniversity/getAllUniversities'];
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    showAddModal() {
      this.error = null;
      this.selectedUniversity = null;
      this.selectedFaculty = null;
      this.selectedDepartment = null;
      this.selectedAcademicYear = null;
      this.newUser = {
        username: '',
        email: '',
        password: '',
        role: '',
        name: '',
        mobile: '',
      };

      this.$refs.addUserModalRef.open();
    },
    async addNewUser() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        this.$refs.addUserModalRef.close();

        const data = {};

        Object.keys(this.newUser).forEach(key => {
          if (this.newUser[key]) {
            data[key] = this.newUser[key];
          }
        });

        if (this.newUser.role === 'student' && this.selectedAcademicYear?.id) {
          data.academicYearId = this.selectedAcademicYear.id;
        }

        const response = await this.$store.dispatch("moduleUser/createNewUser", data).catch((error) => {
          this.$helper.handleError(error, this);
        });

        if (response && (response.status === 200 || response.status === 201)) {
          this.$helper.showMessage('success', this);
          await this.getAllUsers();
        }

        this.newUser = { username: '', email: '', password: '', role: '', name: '', mobile: '' };
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
    setFaculties(universityId) {
      this.faculties = this.universities.find(university => +university.id === +universityId)?.faculties || [];
      this.departments = [];
      this.academicYears = [];
      this.selectedFaculty = null;
      this.selectedDepartment = null;
      this.selectedAcademicYear = null;
    },
    setDepartments(facultyId) {
      this.departments = this.faculties.find(faculty => +faculty.id === +facultyId)?.departments || [];
      this.academicYears = [];
      this.selectedDepartment = null;
      this.selectedAcademicYear = null;
    },
    setAcademicYears(deptId) {
      this.academicYears = this.departments.find(dept => +dept.id === +deptId)?.academicYears || [];
      this.selectedAcademicYear = null;
    }
  }
}
</script>

<style>
.sweet-modal .sweet-title > h2 {
  padding-top: 15px;
}
</style>