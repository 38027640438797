<template>
    <div>
        <div class="image-cover ed_detail_head lg theme-ov" style="background:#f4f4f4;"
             :style="{backgroundImage:'url('+require('@/assets/img/banner-2.jpg')+')'}" data-overlay="9">
            <div class="container">
                <div class="row direction">

                    <div class="col-lg-7 col-md-9">
                        <div class="ed_detail_wrap light">
                            <div class="ed_header_caption">
                                <h1 class="ed_title font-weight-bold fun_font">{{$t('login_teacher')}}</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="bg-light pt-0">
            <div class="container">
                <div class="row direction">
                    <div class="col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <div class="ed_view_box style_2 overlio">

                            <div class="ed_author direction">
                                <div class="ed_author_box">
                                    <h2 class="font-weight-bold fun_font">{{$t('forget_password')}}</h2>
                                    <span>{{$t('forget_password_note')}}</span>
                                </div>
                            </div>

                            <div class="ed_view_price pl-4 direction">
                                <div class="form-group">
                                    <label>{{$t('email')}}<i class="text-danger">*</i></label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="email" v-model="email"
                                           class="form-control"
                                           :placeholder="$t('email')">
                                    <span class="text-danger text-sm" id="email_error">{{ errors.first('email') }}</span>
                                </div>

                              <div class="row" v-if="isClicked">
                                   <div class="col-md-12">
                                        <div class="form-group">
                                            <label>{{$t('confirm_code')}}<i class="text-danger">*</i></label>
                                            <input v-validate="'required'" v-model="code" data-vv-name="code"
                                                   type="text"
                                                   class="form-control"
                                                   :placeholder="$t('confirm_code')">
                                            <span class="text-danger text-sm" id="code_error">{{ errors.first('code') }}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{$t('password')}}<i class="text-danger">*</i></label>
                                            <input v-validate="'required'" v-model="password" data-vv-name="password"
                                                   type="password"
                                                   class="form-control"
                                                   placeholder="*******">
                                            <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{$t('password_confirmation')}}<i class="text-danger">*</i></label>
                                            <input v-validate="'required'" v-model="password_confirmation"
                                                   data-vv-name="password_confirmation"
                                                   type="password"
                                                   class="form-control"
                                                   placeholder="*******">
                                            <span class="text-danger text-sm" id="pass_confirm">{{ errors.first('password_confirmation') }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                             <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>

                            <div class="ed_view_link">
                                <a href="#" class="btn btn-theme enroll-btn" v-if="!isClicked"
                                   @click.prevent="sendVerificationCode()">{{$t('verify')}}
                                </a>
                                 <a href="#" class="btn btn-theme enroll-btn" v-else
                                   @click.prevent="submitForm()">{{$t('update_password')}}
                                </a>
                            </div>
                           

                        </div>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import {ValidationProvider} from 'vee-validate';

    export default {
        name: "Login",
        components: {ValidationProvider},
        data() {
            return {
                email: null,
                password: null,
                code: null,
                password_confirmation:null,

                isClicked:false,
            }
        },
        computed: {
            validateForm() {
                // return true
                // return !this.errors.any() && this.phone != '' && this.password != '';
            },
        },
        methods: {

            sendVerificationCode(){
                  let vm = this;
                vm.$helper.showLoader()
                const payload = {
                    email: vm.email,
                };
                vm.$store.dispatch('moduleAuth/sendVerificationCode', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                         // Navigate User to homepage
                        this.isClicked=true;
                    })
                    .catch(error => {
                        let error_type=error.response.data;
                        if(error_type.message== 'RESOURCE_NOT_FOUND')
                            $('#email_error').text(vm.$helper.getErrorValueLang(error_type.data));
                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                        
                    });
            },

            
            submitForm() {
                 let vm=this;
                    //check if password is similar
                     if( vm.password !=  vm.password_confirmation)
                     {
                        $('#pass_confirm').text(this.$t('password_confirmation_note'));
                        return
                     }
                this.$validator.validateAll().then(result => {
                    if (result) {
                        // if form have no errors
                        this.forgetPassword();
                    } else {
                        // form have validation errors
                // $('html, body').animate({ scrollTop: 19 }, 'slow');
                    }
                })
            },
            forgetPassword(){
                      let vm = this;
                vm.$helper.showLoader()
                const payload = {
                    email: vm.email,
                    password: vm.password,
                    code: vm.code,
                };
                vm.$store.dispatch('moduleAuth/forgetPassword', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                         // Navigate User to login
                        vm.$helper.showMessage('success',vm);
                       vm.$router.push({name:'login'})
                    })
                    .catch(error => {
                        vm.$helper.hideLoader()
                        let error_type=error.response.data;
                        if(error_type.message== 'VERIFICATION_CODE_INCORRECT'){
                            $('#code_error').text(vm.$helper.getErrorValueLang(error_type.data));
                            //to ignore the navigation to login in case of error 401
                            return;
                        }
                     
                        vm.$helper.handleError(error, vm);
                        
                    });
            },

            
        }
    }
</script>

<style scoped>

</style>