<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              @click.prevent
            >
              {{ $t("add_group") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">
                {{ $t("add_group") }}
              </h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("teacher") }}</label>
                  <multiselect
                    :options="instructors"
                     :select-label="$t('multiselect_choose')"
                    :custom-label="$helper.getTitleValueLang"
                    track-by="id"
                    @input="filterListItems()"
                    v-model="selectedInstructor"
                    v-validate="'required'"
                    data-vv-name="instructorId"
                    :placeholder="$t('search')"
                  ></multiselect>
                  <span class="text-danger text-sm">{{
                    errors.first("instructorId")
                  }}</span>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("list") }}</label>
                  <multiselect
                    :options="lists"
                     :select-label="$t('multiselect_choose')"
                    :custom-label="$helper.getTitleValueLang"
                    track-by="id"
                    v-model="selectedList"
                    v-validate="'required'"
                    data-vv-name="courseId"
                    :placeholder="$t('search')"
                  ></multiselect>
                  <span class="text-danger text-sm">{{
                    errors.first("courseId")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- live =>1 , record=>0 -->

      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">
                {{ $t("group") }}
              </h2>
            </div>
          </div>
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("group_name") }}</label>
                  <input
                    type="text"
                    v-validate="'required'"
                    data-vv-name="nameGroup"
                    v-model="dataModel.nameGroup"
                    class="form-control"
                    :placeholder="$t('group_name')"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("nameGroup")
                  }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{
                    $t("limit_numbers")
                  }}</label>
                  <input
                    type="text"
                    data-vv-name="maxNumOfStudentsGroup"
                    v-model="dataModel.maxNumOfStudentsGroup"
                    class="form-control"
                    :placeholder="$t('limit_numbers')"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("maxNumOfStudentsGroup")
                  }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("start_date") }}</label>
                  <flat-pickr
                    data-vv-name="startDateGroup"
                    v-model="dataModel.startDateGroup"
                    class="form-control"
                    :placeholder="$t('start_date')"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("startDateGroup")
                  }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("end_date") }}</label>
                  <flat-pickr
                    data-vv-name="endDateGroup"
                    v-model="dataModel.endDateGroup"
                    class="form-control"
                    :placeholder="$t('end_date')"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("endDateGroup")
                  }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t("times") }}</label>
                  <ul>
                    <li class="mb-1">
                      <div class="row">
                        <div
                          class="col-md-6 mb-1"
                          v-for="(day, index) in $helper.getDays()"
                          :key="index"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <input
                                :id="'day_' + index"
                                type="checkbox"
                                name="day_index[]"
                                :value="day"
                                @change="addGroupSchedule()"
                                v-model="days[index]"
                              />
                              <label
                                :for="'day_' + index"
                                class="font-weight-bold p-1"
                                >{{ day }}</label
                              >
                            </div>
                            <div class="col-md-6">
                              <flat-pickr
                                :config="config"
                                v-model="times[index]"
                                @input="chooseDay(index)"
                                class="form-control text-center"
                                :placeholder="'00:00'"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <span class="text-danger text-sm mr-4">{{
                  errors.first("time")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="dashboard_container_body p-2">
            <div class="row">
              <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>

              <div class="col-md-12 text-center mt-2">
                <button class="btn btn-danger-light" @click="submitForm()">
                  {{ $t("add") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import moment from "moment";

export default {
  name: "Add",
  components: { VueCropper },
  data() {
    return {
      dataModel: {
        instructorId: {},
        courseId: {},

        ///////////
        nameGroup: "",
        maxNumOfStudentsGroup: "",
        startDateGroup: "",
        endDateGroup: "",
        groupSchedule: [],
      },

      //     ///////////
      //     nameGroup: '',
      //     maxNumOfStudentsGroup: '',
      //     startDateGroup: '',
      //     endDateGroup: '',
      //     groupSchedule:[],

      // },
      imgSrc: null,
      times: [],
      days: [],
      lists: [],
      selectedList: null,
      selectedInstructor: {},
      instructors: [],
      config: {
        enableTime: true,
          noCalendar: true,
        dateFormat: "Z",
        altInput: true,
        altFormat: "Y-m-d h:i K",
      },
    };
  },
  mounted() {
    // this.getAllLists();
    this.getAllTeachers();

    var now = "2018-06-28 01:30:20";
    var date = moment(new Date(now)).format("YYYY-MM-DD HH:mm:ss");
  },
  methods: {
    filterListItems() {
      if (!this.selectedInstructor) return;

      this.getAllLists();
    },
    addGroupSchedule() {
      this.dataModel.groupSchedule = [];
      this.days.map((day, index) => {
        if (day != null)
          this.dataModel.groupSchedule.push({
            day: this.$helper.getDays("en")[index],
            time: this.times[index],
          });
      });
    },
    chooseDay(index) {
      $(`#day_${index}`).prop("checked", true);

      //append the index of the checked button
      this.days[index] = true;

      //rerender the schedule group
      this.addGroupSchedule();
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },

    getAllLists() {
      let vm = this;
      if (!vm.selectedInstructor) return;

      if (this.selectedList) this.selectedList = [];
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.method = 1;
      // filters.method = "both";
      let payload = {};
      payload = filters;
      payload.instructorId = vm.selectedInstructor.id;

// "moduleList/listCourseByInstructor"  
      let dispatch = this.$store.dispatch(
        "moduleList/getAllList",
        payload
      );
      dispatch
        .then((response) => {
          //get list id from params
          let list_id=vm.$route.params.id;
          vm.lists = response.data.data.result.data;

          //search for the selected list
          vm.lists.map(list=>{
            if(list_id == list.id)
              vm.selectedList=list;
          });
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    getAllTeachers() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch(
        "moduleTeacher/getAllTeachers",
        filters
      );
      dispatch
        .then((response) => {
          vm.instructors = this.$store.getters["moduleTeacher/getAllTeachers"];
          if (vm.instructors.length > 0) {
            vm.selectedInstructor = vm.instructors[0];
            this.getAllLists();
          }
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // if form have no errors
          this.addGroup();
        } else {
          // form have validation errors
          $("html, body").animate({ scrollTop: 19 }, "slow");
           this.$helper.printValidationError(this.$validator.errors,this);
        }
      });
    },
    addGroup() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};

      //////////THIS SNIPPEST OF CODE TO CONVERT GROUPSCHEDULE TO STRING///////////
      let groupSchedule_string = JSON.stringify(vm.dataModel.groupSchedule);
      vm.dataModel.groupSchedule = groupSchedule_string;
      //////////////////////////////END OF CODE////////////////////////////////

      const formData = new FormData();
      vm.dataModel.instructorId = vm.selectedInstructor
        ? vm.selectedInstructor.id
        : "";
      vm.dataModel.courseId = vm.selectedList ? vm.selectedList.id : "";
      _.forEach(vm.dataModel, (value, key) => {
        if (value != null) formData.append(key, value);
      });

      payload.data = formData;
      payload.id = vm.$route.params.id;
      vm.$store
        .dispatch("moduleGroup/addGroup", payload)
        .then(() => {
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
          vm.$router.push({ name: this.$helper.getRouteFullName("all_groups")}).catch(()=>{});
        })
        .catch((error) => {
          vm.dataModel.groupSchedule = JSON.parse(vm.dataModel.groupSchedule);
          if(error.response){
          let error_type = error.response.data;
 
          if (
            error_type.message === "RESOURCE_NOT_FOUND" ||
            error_type.message === "UNACCEPTABLE_DATE"
          )
            $("#error_handle").text(
              vm.$helper.getErrorValueLang(error_type.data)
            );
          }
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
  },
};
</script>

<style scoped>
</style>