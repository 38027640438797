<template>
    <div>
        <Navigation/>

        <u-animate-container>
            <div class="row">
                <div class="col-md-12">
                    <div class="card pt-0 mt-0">
                        <div class="card-header border-bottom-1">
                            <h4 class="font-weight-bold">{{$t('my_assignments')}} ({{assignments.length}})</h4>
                        </div>
                        <div class="card-body">
                            <div class="p-5 text-center" v-if="assignments.length==0">
                                <i class="ti-book fa-3x color--primary"></i>
                                <h3>{{$t('no_data')}}</h3>
                            </div>
                           <div class="card-body">
              <div class="p-2" v-for="(assignment,index) in assignments" :key="index">
                <u-animate
                  name="bounceInDown"
                  :delay="index/100+'s'"
                  duration="0.5s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animated"
                  :begin="false"
                >
                  <div
                    class="row direction text-left pointer"
                  >
                    <div class="col-md-12">
                      <div class="row" @click="$router.push({name:$helper.getRouteFullName('all_assignments')}).catch(()=>{})">
                        <div class="col-md-8">
                          <h4 class="text-danger">
                            <i class="ti-user text-danger"></i>
                            {{$helper.getTitleValueLang(assignment)}}
                          </h4>
                          <h6> {{ submissionStatusValues[
                          assignment.status ? assignment.status : 2
                        ] }}</h6>
                           <p> {{assignment.statusComments?assignment.statusComments:''}}</p>
                        </div>
                        <div class="col-md-4 direction-inverse text-right " >
                          <div>
                            <i class="ti-calendar text-danger theme-cl"></i>
                            <span class="font-weight-bold text-dark text-small"> {{assignment.updatedAt}}</span>
                            <p class="font-weight-bold text-dark text-small" style="direction: rtl;"> {{$t('student_name')}}:{{assignment.student.name}}</p>
                            <p class="font-weight-bold text-dark text-small" style="direction: rtl;">{{$t('date')}}:{{$helper.formatDate(assignment.student.updatedAt)}}</p>
                          </div>
                          <div>
                            <label class="badge badge-danger"> {{$helper.getTitleValueLang(assignment.lesson.course)}}</label>
                          </div>
                        </div>
                      
                      </div>
                        <div class="col-12 text-center">
                          <button class="btn btn-danger-light btn-sm" @click="openFile(assignment)">{{$t('show_file')}}</button>
                            <button
                          class="btn custom_btn btn-success-light"
                          v-if="$helper.isInstructor()"
                          @click="openAssignmentEvaluate(assignment)"
                        >
                          {{ $t("review") }}
                        </button>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="index < assignments.length -1">
                      <hr class="hr_bg" />
                    </div>
                  </div>
                </u-animate>
              </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </u-animate-container>
          <sweet-modal
      ref="evalute"
      class="p-0"
      overlay-theme="dark"
      modal-theme="dark"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 mt-3 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("status") }}</label>
            <multiselect
             :select-label="$t('multiselect_choose')"
              :options="submissionStatusValues"
              v-validate="'required'"
              data-vv-name="status"
              v-model="submissionStatus.status"
              class="form-control"
              :placeholder="$t('status')"
            ></multiselect>

            <span class="text-danger text-sm" id="status">{{
              errors.first("status")
            }}</span>
          </div>
          </div>
          <div class="col-12 mr-1">
            <div class="form-group">
            <label class="font-weight-bold">{{ $t("comment") }}</label>
            <!-- type="text" -->

            <textarea
              v-validate="'required'"
              data-vv-name="statusComments"
              v-model="submissionStatus.statusComments"
              class="form-control"
              :placeholder="$t('comment')"
            />
            <span class="text-danger text-sm" id="statusComments">{{
              errors.first("statusComments")
            }}</span>
          </div>
           <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>
          </div>
          <div class="col-12 mt-3">
            <button class="btn my-2 my-sm-0 text-secondary" type="submit" @click="validateEvalutionForm">
              {{ $t("add") }}
            </button>
          </div>
        </div>
      </div>
    </sweet-modal>
    </div>
</template>

<script>
    import Navigation from './Navigation'
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

    export default {
        name: "Discussions",
        components: {Navigation,SweetModal, SweetModalTab},
        data(){
            return{
            assignments:[],
             submissionStatusValues: [
        this.$t("submission_reject"),
        this.$t("submission_accept"),
        this.$t("submission_pending"),
      ],
       submissionStatus: {},
            };
        },
        mounted(){
            this.getAllLatestAssignments();
        },
        methods:{
            prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 1000,
        page: 1,
        searchKey: this.query ? this.query : "",
      };
    },
   
     openFile(assignment){
      $('<a href='+`${assignment.attachments}`+' target="blank"></a>')[0].click();    

    },
     openAssignmentEvaluate(assignment) {
      this.submissionStatus.id=assignment.id;
      this.$refs.evalute.open();
    },
     validateEvalutionForm(){
      //validate fields
      if(!this.submissionStatus.status)
        $('#status').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(!this.submissionStatus.statusComments)
        $('#statusComments').text(this.$t('isRequired'));
      else
        $('#status').text('');

      if(this.submissionStatus.statusComments && this.submissionStatus.status){
         this.addEvalution();
      }
    },
 getAllLatestAssignments() {
      let vm = this;
      let helper=vm.$helper;
    
        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');

       helper.showLoader();
      let filters = vm.prepareFilters();
      let payload = {};
      payload.filters = filters;
      payload.filters.instructorId = data?data.instructorId:'';
      payload.filters.courseId = vm.$route.params.id;
      payload.filters.orderBy = 'DESC';

      let dispatch = this.$store.dispatch(
        "moduleAssignmentSubmission/getAllAssignmentSubmissions",
        payload
      );
      dispatch
        .then((response) => {
         vm.assignments = this.$store.getters[
            "moduleAssignmentSubmission/getAllAssignmentSubmission"
          ];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },
    addEvalution(){
                let vm = this;
                vm.$helper.showLoader();
                let payload = {};
                payload.id=this.submissionStatus.id;

                payload.data={};
                payload.data.status=this.submissionStatusValues.indexOf(this.submissionStatus.status);
                payload.data.statusComments=this.submissionStatus.statusComments;
                 
                vm.$store.dispatch('moduleAssignmentSubmission/evaluateSubmission', payload)
                    .then(() => {
                          vm.$helper.hideLoader()
                        vm.$helper.showMessage('success', vm)

                        //clear data from modal
                        delete this.submissionStatus.statusComments;
                        delete this.submissionStatus.status;
                        delete this.submissionStatus.id;

                        //close modal
                         this.$refs.evalute.close();
                        this.$router.go();
                      })
                    .catch(error => {
                         let error_type=error.response.data;
                        if(error_type.message == 'ASSIGNMENT_SUBMISSION_UNEDITABLE')
                           $('#error_handle').text(this.$helper.getErrorValueLang(error_type.data));

                           //clear data from modal
                        this.submissionStatus.statusComments="";
                        this.submissionStatus.status="";
                       

                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                      
                    })
            
    }

        },
    }
</script>

<style>
    section {
        padding: 0;
    }
</style>