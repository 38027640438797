<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t("dashboard") }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >
              {{ $t("assignment_subscriptions") }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center py-2">
            {{ $t("assignment_subscriptions") }}
          </h2>

          <div class="dashboard_container_body my-2 mx-3">
            <p class="text-lg-center font-weight-bold" v-if="items.length === 0">{{ $t('no_data') }}</p>

            <ul class="row" v-if="items.length">
              <li v-for="(item, index) in items" :key="item.subjectId" class="col-sm-6 col-md-4 col-lg-3">
                <figure class="notification-card d-flex justify-content-between" @click="$router.push({name:'assignment_subscriptions_details',params:{id:item.subjectId}}).catch(()=>{});">
                  <h6 class="m-0">{{ item.subjectName }}</h6><h6 class="m-0 badge badge-primary">{{ item.count }}</h6>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "All",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.getAssignments();
  },
  methods: {
    async getAssignments() {
      let vm = this;
      vm.$helper.showLoader();

      let response = await this.$store.dispatch('moduleAssignmentSubmission/getAssignments', { type: 0 }).catch((error) => {
        vm.$helper.handleError(error, vm);
      });

      vm.$helper.hideLoader();

      vm.items = response?.data?.data || [];
    },
  },
};
</script>

<style scoped>
</style>
