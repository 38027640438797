<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('courses') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ courseName }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>
              {{ $t('private_sessions_subscriptions') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row p-2">
            <div class="col-12 pt-3 text-center">
              <h2 class="font-weight-bold fun_font">{{ $t('private_sessions_subscriptions') }}</h2>
              <p>جميع إشتراكات الطلاب بالمحاضرات الخاصة بهذه الدورة</p>
            </div>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('student_name') }}</th>
                  <th class="text-left">{{ $t('phone') }}</th>
                  <th class="text-left">{{ $t('total') }}</th>
                  <th class="text-left">{{ $t('status') }}</th>
                  <th class="text-left">{{ $t('lectures_count') }}</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(subscription , index) in subscriptions">
                  <tr :key="index">
                    <td v-if="subscription?.session_topics?.length > 0">
                      <i class="fa fa-plus-square cursor-pointer" style="font-size: 22px" v-if="!subscription.isExpanded" @click="toggleExpanded(subscription)"></i>
                      <i class="fa fa-minus-square cursor-pointer" style="font-size: 22px" v-else @click="toggleExpanded(subscription)"></i>
                    </td>
                    <td v-else></td>
                    <td>{{ index + 1 }}</td>
                    <td>{{ subscription?.user?.student?.name || '' }}</td>
                    <td>
                      <a :href="'tel:'+subscription?.user?.student?.mobile || ''">{{ subscription?.user?.student?.mobile || '' }}</a>
                    </td>
                    <td>{{ subscription?.price || '' }}</td>
                    <td><h6 class="badge badge-secondary">{{ $t(subscription?.paymentResult) }}</h6></td>
                    <td>{{ subscription?.session_topics?.length || 0 }}</td>
                  </tr>
                  <td scope="row" colspan="7" :key="index + '-topics'" v-show="subscription?.session_topics?.length > 0 && subscription.isExpanded">
                    <table class="table">
                      <thead class="thead-default">
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $t('lecture_name') }}</th>
                        <th class="text-left" width="150px">{{ $t('video') }}</th>
                        <th class="text-left">{{ $t('attachments') }}</th>
                        <th class="text-left" width="180px">{{ $t('operation') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(sTopic, index1) in subscription.session_topics" :key="index + '-' + index1">
                          <td>{{ index1 + 1 }}</td>
                          <td>{{ $helper.getTitleValueLang(sTopic?.lesson || sTopic?.topic || {}) || '' }}</td>
                          <td>
                            <a class="btn btn-danger-light p-1" v-if="sTopic?.lesson" @click="displayVedio(sTopic?.lesson?.vedio || '')">
                              <i class="ti ti-video-clapper"></i> {{ $t('show_video') }}
                            </a>
                            <p class="m-auto" v-else>لم يتم الرفع بعد</p>
                          </td>
                          <td>
                            <div v-if="sTopic?.lesson && (sTopic.lesson.attachments || (sTopic.lesson.otherAttaches && sTopic.lesson.otherAttaches.length))" class="d-flex flex-wrap">
                              <div class="thumbnail" v-if="sTopic.lesson.attachments">
                                <button class="btn btn-danger-light p-1" @click="displayImage(sTopic.lesson.attachments)">{{ $t('show') }}</button>
                                <i class="btn-danger-light ti-trash" @click="deleteLessonAttachments(sTopic.lesson.attachments, sTopic.lesson.id)"/>
                              </div>

                              <div class="thumbnail" v-for="(item, otherIndex) in sTopic.lesson.otherAttaches" :key="'otherAttach-' + otherIndex">
                                <button class="btn btn-danger-light p-1" @click="displayImage(item.attachment)">{{ $t('show') }}</button>
                                <i class="btn-danger-light ti-trash" @click="deleteOtherAttachment(item.id)"/>
                              </div>
                            </div>
                            <p class="m-auto" v-else>لا يوجد</p>
                          </td>
                          <td>
                            <button class="btn custom_btn btn-primary-light" @click="openVideoBrowse(subscription.id, sTopic.id)">
                              {{ $t('upload_video') }}
                            </button>
                            <button class="btn custom_btn btn-success-light" @click="openAttachmentBrowse(subscription.id, sTopic.id)">
                              {{ $t('upload_attachment') }}
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </template>
                </tbody>
              </table>

              <input type="file" @change="onBrowseFile()" ref="attachmentsInput" multiple style="display: none"/>
              <input type="file" @change="onBrowseVideo()" ref="videoInput" accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv" style="display: none" />

              <p class="text-lg-center font-weight-bold" v-if="subscriptions.length === 0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="playVimeoModal" class="p-0" overlay-theme="dark" modal-theme="dark">
      <div class="container">
        <div class="row">
          <div class="col-mds-12 w-100" v-if="videoIdToPlay">
            <vue-vimeo-player ref="player" :video-id="videoIdToPlay" @ready="onReady" :player-height="300" style="width: 100%">
            </vue-vimeo-player>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { SweetModal } from "sweet-modal-vue";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  name: "All",
  components: { SweetModal, vueVimeoPlayer },
  data() {
    return {
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.name,
      subscriptions: [],
      videoIdToPlay: null,
      playerReady: false,
      dataModel: {
        privateSessionId: null,
        sessionTopicId: null,
        attachments: null,
        video: null,
      }
    }
  },
  mounted() {
    this.getAllSubscriptions();
  },
  methods: {
    getAllSubscriptions() {
      let vm = this;
      if (!vm.courseId) {
        vm.$helper.showMessage('error', vm, 'برجاء تحديد الدورة لتحميل المحاضرات');
        return;
      }

      vm.$helper.showLoader();
      this.$store.dispatch("moduleList/getCourseSessionsSubscriptions", { courseId: vm.courseId }).then(res => {
        vm.subscriptions = (res?.data?.data?.data || []).map(item => {
          const subscription = (vm.subscriptions || []).find(sub => sub.id === item.id);
          item.isExpanded = subscription?.isExpanded || false;
          return item;
        });
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    toggleExpanded(subscription) {
      subscription.isExpanded = !subscription.isExpanded;
      this.$forceUpdate();
    },
    onReady() {
      this.playerReady = true
    },
    displayImage(image_path) {
      window.open(image_path, '_blank').moveTo(0, 0);
    },
    displayVedio(vedio_path) {
      this.videoIdToPlay = vedio_path.split('/').slice(-1)[0];
      this.$refs.playVimeoModal.open();
    },
    onBrowseFile() {
      if (this.$refs.attachmentsInput?.files?.length) {
        let vm = this;
        vm.$swal({
          title: vm.$t("warning"),
          text: vm.$t("are_you_sure"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: vm.$t("yes"),
          cancelButtonText: vm.$t("no"),
        }).then(async (result) => {
          if (result.value) {
            await vm.updateSessionTopic(null, this.$refs.attachmentsInput.files);
          }

          this.$refs.attachmentsInput.value = '';
        });
      }
    },
    onBrowseVideo() {
      if (this.$refs.videoInput?.files?.length) {
        let vm = this;
        vm.$swal({
          title: vm.$t("warning"),
          text: vm.$t("are_you_sure"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: vm.$t("yes"),
          cancelButtonText: vm.$t("no"),
        }).then(async (result) => {
          if (result.value) {
            await vm.updateSessionTopic(this.$refs.videoInput.files[0], null);
          }

          this.$refs.videoInput.value = '';
        });
      }
    },
    openVideoBrowse(privateSessionId, sessionTopicId) {
      this.dataModel.privateSessionId = privateSessionId;
      this.dataModel.sessionTopicId = sessionTopicId;
      this.$refs.videoInput.click();
    },
    openAttachmentBrowse(privateSessionId, sessionTopicId) {
      this.dataModel.privateSessionId = privateSessionId;
      this.dataModel.sessionTopicId = sessionTopicId;
      this.$refs.attachmentsInput.click();
    },
    updateSessionTopic(video, attachments) {
      let vm = this;
      vm.$helper.showLoader();

      const payload = {
        privateSessionId: vm.dataModel.privateSessionId,
        sessionTopicId: vm.dataModel.sessionTopicId,
        config: {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            $('#loader_message').text(percentCompleted + ' %');
          }
        }
      };

      const formData = new FormData();
      video && formData.append('vedio', video);
      attachments && Object.values(attachments) && Object.values(attachments).forEach(attachment => formData.append('attachments', attachment));

      payload.data = formData;

      this.$store.dispatch("moduleList/updateSessionTopic", payload).then(() => {
        vm.$helper.hideLoader();
        vm.$helper.showMessage('success', vm, 'تم الرفع بنجاح');
        this.getAllSubscriptions();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    deleteLessonAttachments(src, lessonId) {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {
        id: lessonId,
        data: { attachmentPath: src ? src.split('/').slice(3).join('/') : '' }
      };

      this.$store.dispatch("moduleCourse/deleteCourseAttachments", payload).then((response) => {
        if (response.status) {
          vm.$helper.showMessage("success", this);
          this.getAllSubscriptions();
        }
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    deleteOtherAttachment(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleCourse/deleteCourseOtherAttachments", { id }).then((response) => {
        if (response.status) {
          vm.$helper.showMessage("success", this);
          this.getAllSubscriptions();
        }
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
  },
};
</script>
