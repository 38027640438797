import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllList({ commit }, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_LIST, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_LIST', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getUserLists({ commit }, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_USER_LIST, {
                params: filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    checkoutList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CHECK_OUT_LIST, payload).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error);
            })
        })
    },
    findList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_LIST}/${payload.id}`, {
                params: payload.filters
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    listCourseByInstructor({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_ALL_LIST_ByInstructor}/${payload.id}`, {
                params: payload.filters
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.CREATE_LIST}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getVedioDetails({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${payload.vedio_path}`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.UPDATE_LIST}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_LIST}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getAllCoupons({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_COUPONS, { params: params }).then((response) => {
                if (response.data.status) {
                    commit('SET_LIST', response.data.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addCoupon({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.ADD_COUPON}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeCoupon({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.DELETE_COUPON}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getAllDiscounts({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_DISCOUNTS, { params: params }).then((response) => {
                if (response.data.status) {
                    commit('SET_LIST', response.data.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addDiscount({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.ADD_DISCOUNT}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeDiscount({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(`${requests.DELETE_DISCOUNT}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getAllMarketers({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_MARKETERS, { params: params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAllSemesters({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_SEMESTERS, { params: params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getAllSections({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_SECTIONS, { params: params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addSection({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.ADD_SECTION, payload.data, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateSections({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(requests.UPDATE_SECTIONS, payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteSection({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(requests.DELETE_SECTION(payload.id)).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getCourseTopics({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(requests.COURSE_TOPICS(payload.courseId), { params: payload.params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addCourseTopic({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.COURSE_TOPICS(payload.courseId), payload.data, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateCourseTopic({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(requests.UPDATE_COURSE_TOPIC(payload.courseId, payload.topicId), payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteCourseTopic({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(requests.DELETE_COURSE_TOPIC(payload.courseId, payload.topicId)).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getCourseSessionsSubscriptions({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(requests.COURSE_SESSIONS_SUBSCRIPTIONS, { params: payload }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateSessionTopic({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(requests.UPDATE_SESSION_TOPIC(payload.privateSessionId, payload.sessionTopicId), payload.data, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getHomeworkList({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_HOMEWORK_LIST, { params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getQuizList({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_QUIZ_LIST, { params }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addHomeworkItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.HOMEWORKS_LIST + '/add', payload.data, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addQuizItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.QUIZZES_LIST + '/add', payload.data, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateHomeworkItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(requests.HOMEWORKS_LIST + '/' + payload.id + '/update', payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    updateQuizItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.patch(requests.QUIZZES_LIST + '/' + payload.id + '/update', payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteHomeworkItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(requests.HOMEWORKS_LIST + '/' + payload.id + '/delete', payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    deleteQuizItem({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(requests.QUIZZES_LIST + '/' + payload.id + '/delete', payload.data).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
}
