/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store/store.js"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [

        // {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            // path: '',
            // component: () => import('./layouts/main/MainIndex.vue'),
            // children: [
            //     {
            //         path: '/',
            //         redirect: '/home'
            //     },
            //     {
            //         path: '/home',
            //         name: 'home',
            //         component: () => import('./views/userLayout/Home.vue'),
            //         meta: {
            //             pageTitle: 'home',
            //             authRequired: false,
            //             isAdmin: false,
            //         }
            //     },
            // ],
        // },
        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
                {
                    path: '/',
                    redirect: '/home'
                },
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('./views/userLayout/Home.vue'),
                    meta: {
                        pageTitle: 'home',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path:'/pages/Error403',
                    name: 'pageError403',
                    component: () => import('./views/pages/Error403.vue'),
                    meta: {
                        pageTitle: 'Error 403',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/course_detail/:id',
                    name: 'course_detail',
                    component: () => import('./views/userLayout/CourseDetail.vue'),
                    meta: {
                        pageTitle: 'course detail',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import('./views/userLayout/Profile.vue'),
                    meta: {
                        pageTitle: 'profile',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/update_password',
                    name: 'update_password',
                    component: () => import('./views/userLayout/UpdatePassword.vue'),
                    meta: {
                        pageTitle: 'update_password',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/my_courses',
                    name: 'my_courses',
                    component: () => import('./views/userLayout/MyCourses.vue'),
                    meta: {
                        pageTitle: 'my_courses',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/search',
                    name: 'search',
                    component: () => import('./views/userLayout/Search.vue'),
                    meta: {
                        pageTitle: 'search',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/login',
                    name: 'login',
                    component: () => import('./views/teacherLayout/Login.vue'),
                    meta: {
                        pageTitle: 'login',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/forget_password',
                    name: 'forget_password',
                    component: () => import('./views/teacherLayout/forget_password.vue'),
                    meta: {
                        pageTitle: 'forget password',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/register',
                    name: 'register',
                    component: () => import('./views/teacherLayout/Register.vue'),
                    meta: {
                        pageTitle: 'register',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/admin/login',
                    name: 'login_admin',
                    component: () => import('./views/adminLayout/Login.vue'),
                    meta: {
                        pageTitle: 'login admin',
                        authRequired: false,
                        isAdmin: false,
                    }
                },
            ],
        },

        {
            // =============================================================================
            // Teacher LAYOUT ROUTES
            // =============================================================================
            path: '/',
            component: () => import('./layouts/main/TeacherMain.vue'),
            children: [
                {
                    path: '/teacher',
                    redirect: '/teacher/dashboard'
                },
                {
                    path: '/teacher/dashboard',
                    name: 'teacher_dashboard',
                    component: () => import('./views/teacherLayout/Dashboard.vue'),
                    meta: {
                        pageTitle: 'Teacher Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/courses/:id/lessons',
                    name: 'teacher_dashboard_courses',
                    component: () => import('./views/teacherLayout/myCourse/Courses.vue'),
                    meta: {
                        pageTitle: 'Teacher Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_teachers/edit/:id',
                    name: 'profile_teacher',
                    component: () => import('./views/teacherLayout/teacher_profile.vue'),
                    meta: {
                        pageTitle: 'teacher profile',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/notify_subscribers',
                    name: 'notify_subscribers',
                    component: () => import('./views/teacherLayout/notify_subscribers.vue'),
                    meta: {
                        pageTitle: 'Notify Subscribers',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },


                {
                    path: '/teacher/all_courses',
                    name: 'all_courses_teacher',
                    component: () => import('./views/adminLayout/Courses/All.vue'),
                    meta: {
                        pageTitle: 'All Courses',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_courses/add/:list_id',
                    name: 'add_courses_teacher',
                    component: () => import('./views/adminLayout/Courses/Add.vue'),
                    meta: {
                        pageTitle: 'Add Courses',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_lists',
                    name: 'all_lists_teacher',
                    component: () => import('./views/adminLayout/List/All.vue'),
                    meta: {
                        pageTitle: 'All Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/manage_lessons',
                    name: 'manage_lessons_teacher',
                    component: () => import('./views/adminLayout/ManageLessons/All.vue'),
                    meta: {
                        pageTitle: 'Manage Lessons',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/coupons',
                    name: 'coupons_teacher',
                    component: () => import('./views/adminLayout/Coupons/All.vue'),
                    meta: {
                        pageTitle: 'Coupons',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/add_coupon',
                    name: 'add_coupon_teacher',
                    component: () => import('./views/adminLayout/Coupons/Add'),
                    meta: {
                        pageTitle: 'Add Coupon',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_lists/add',
                    name: 'add_lists_teacher',
                    component: () => import('./views/adminLayout/List/Add.vue'),
                    meta: {
                        pageTitle: 'Add Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_groups',
                    name: 'all_groups_teacher',
                    component: () => import('./views/adminLayout/Groups/All.vue'),
                    meta: {
                        pageTitle: 'All Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_groups/add/:id',
                    name: 'add_groups_teacher',
                    component: () => import('./views/adminLayout/Groups/Add.vue'),
                    meta: {
                        pageTitle: 'Add Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_groups/edit/:id',
                    name: 'edit_groups_teacher',
                    component: () => import('./views/adminLayout/Groups/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_courses/edit/:list_id',
                    name: 'edit_courses_teacher',
                    component: () => import('./views/adminLayout/Courses/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Courses',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/all_lists/edit/:id',
                    name: 'edit_lists_teacher',
                    component: () => import('./views/adminLayout/List/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },


                {
                    path: '/teacher/courses/:id/discussions',
                    name: 'teacher_dashboard_discussions',
                    component: () => import('./views/teacherLayout/myCourse/Discussions.vue'),
                    meta: {
                        pageTitle: 'Teacher Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/courses/:id/assignments',
                    name: 'teacher_dashboard_assignments',
                    component: () => import('./views/teacherLayout/myCourse/Assignments.vue'),
                    meta: {
                        pageTitle: 'Teacher Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/courses/:id/students',
                    name: 'teacher_dashboard_students',
                    component: () => import('./views/teacherLayout/myCourse/Students.vue'),
                    meta: {
                        pageTitle: 'Teacher Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },



                {
                    path: '/teacher/all_discussions',
                    name: 'all_discussions_teacher',
                    component: () => import('./views/adminLayout/Discussion/All.vue'),
                    meta: {
                        pageTitle: 'All Discussions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/add_discussions',
                    name: 'add_discussions_teacher',
                    component: () => import('./views/adminLayout/Discussion/Add.vue'),
                    meta: {
                        pageTitle: 'Add Discussions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },

                {
                    path: '/teacher/all_assignments',
                    name: 'all_assignments_teacher',
                    component: () => import('./views/adminLayout/Assignments/All.vue'),
                    meta: {
                        pageTitle: 'All Assignments',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/reports/getAllUsersReport',
                    name: 'getAllUsersReport_teacher',
                    component: () => import('./views/adminLayout/Reports/getAllUsersReport.vue'),
                    meta: {
                        pageTitle: 'getAllUsersReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/show_student_assignment/:id',
                    name: 'show_student_assignment_teacher',
                    component: () => import('./views/adminLayout/Assignments/AllStudentAssignments.vue'),
                    meta: {
                        pageTitle: 'All Student Assignments',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },

                {
                    path: '/teacher/subscription_points/:id',
                    name: 'subscription_points',
                    component: () => import('./views/adminLayout/subscription_points/All.vue'),
                    meta: {
                        pageTitle: 'Subscription Points',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/topics',
                    name: 'course_topics_teacher',
                    component: () => import('./views/adminLayout/PrivateSessions/CourseTopics.vue'),
                    meta: {
                        pageTitle: 'Course Topics',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
                {
                    path: '/teacher/sessions_subscriptions',
                    name: 'course_sessions_subscriptions_teacher',
                    component: () => import('./views/adminLayout/PrivateSessions/CourseSessionSubscriptions.vue'),
                    meta: {
                        pageTitle: 'Course Sessions Subscriptions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: false,
                    }
                },
            ]
        },
        {
            // =============================================================================
            // Admin LAYOUT ROUTES
            // =============================================================================
            path: '/',
            component: () => import('./layouts/main/AdminMain.vue'),
            children: [
                {
                    path: '/admin',
                    // redirect: '/admin/dashboard'
                    redirect: '/admin/all_lists'
                },
                {
                    path: '/admin/dashboard',
                    name: 'dashboard',
                    component: () => import('./views/adminLayout/Dashboard.vue'),
                    meta: {
                        pageTitle: 'Dashboard',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_universities',
                    name: 'all_universities',
                    component: () => import('./views/adminLayout/Universities/All.vue'),
                    meta: {
                        pageTitle: 'All Universities',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_universities/add',
                    name: 'add_university',
                    component: () => import('./views/adminLayout/Universities/Add.vue'),
                    meta: {
                        pageTitle: 'Add Universities',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_universities/edit/:id',
                    name: 'edit_university',
                    component: () => import('./views/adminLayout/Universities/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Universities',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_faculties',
                    name: 'all_faculties',
                    component: () => import('./views/adminLayout/Faculities/All.vue'),
                    meta: {
                        pageTitle: 'All Faculty',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_faculties/add',
                    name: 'add_faculty',
                    component: () => import('./views/adminLayout/Faculities/Add.vue'),
                    meta: {
                        pageTitle: 'Add Faculty',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_faculties/edit/:id',
                    name: 'edit_faculty',
                    component: () => import('./views/adminLayout/Faculities/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Faculities',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_categories',
                    name: 'all_categories',
                    component: () => import('./views/adminLayout/Categories/All.vue'),
                    meta: {
                        pageTitle: 'All Categories',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_categories/add',
                    name: 'add_categories',
                    component: () => import('./views/adminLayout/Categories/Add.vue'),
                    meta: {
                        pageTitle: 'Add Categories',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_categories/edit/:id',
                    name: 'edit_categories',
                    component: () => import('./views/adminLayout/Categories/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Categories',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_academicyear',
                    name: 'all_academicyear',
                    component: () => import('./views/adminLayout/Academicyear/All.vue'),
                    meta: {
                        pageTitle: 'All AcademicYear',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_academicyear/add',
                    name: 'add_academicyear',
                    component: () => import('./views/adminLayout/Academicyear/Add.vue'),
                    meta: {
                        pageTitle: 'Add AcademicYear',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_academicyear/edit/:id',
                    name: 'edit_academicyear',
                    component: () => import('./views/adminLayout/Academicyear/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit AcademicYear',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/notify_users',
                    name: 'notify_users',
                    component: () => import('./views/adminLayout/notify_users.vue'),
                    meta: {
                        pageTitle: 'Notify Users',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/manage_lessons',
                    name: 'manage_lessons',
                    component: () => import('./views/adminLayout/ManageLessons/All.vue'),
                    meta: {
                        pageTitle: 'Manage Lessons',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/coupons',
                    name: 'coupons',
                    component: () => import('./views/adminLayout/Coupons/All.vue'),
                    meta: {
                        pageTitle: 'Coupons',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/add_coupon',
                    name: 'add_coupon',
                    component: () => import('./views/adminLayout/Coupons/Add'),
                    meta: {
                        pageTitle: 'Add Coupon',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_subjects',
                    name: 'all_subjects',
                    component: () => import('./views/adminLayout/Subjects/All.vue'),
                    meta: {
                        pageTitle: 'All Subjects',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_subjects/add',
                    name: 'add_subjects',
                    component: () => import('./views/adminLayout/Subjects/Add.vue'),
                    meta: {
                        pageTitle: 'Add Subjects',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_subjects/edit/:id',
                    name: 'edit_subjects',
                    component: () => import('./views/adminLayout/Subjects/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Subjects',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_lists',
                    name: 'all_lists',
                    component: () => import('./views/adminLayout/List/All.vue'),
                    meta: {
                        pageTitle: 'All Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_lists/add',
                    name: 'add_lists',
                    component: () => import('./views/adminLayout/List/Add.vue'),
                    meta: {
                        pageTitle: 'Add Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_lists/edit/:id',
                    name: 'edit_lists',
                    component: () => import('./views/adminLayout/List/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Lists',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_admins',
                    name: 'all_admins',
                    component: () => import('./views/adminLayout/Admins/All.vue'),
                    meta: {
                        pageTitle: 'All Admins',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_admins/add',
                    name: 'add_admins',
                    component: () => import('./views/adminLayout/Admins/Add.vue'),
                    meta: {
                        pageTitle: 'Add Admin',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_admins/edit/:id',
                    name: 'edit_admins',
                    component: () => import('./views/adminLayout/Admins/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Admins',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_discussions',
                    name: 'all_discussions',
                    component: () => import('./views/adminLayout/Discussion/All.vue'),
                    meta: {
                        pageTitle: 'All Discussions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/add_discussions',
                    name: 'add_discussions',
                    component: () => import('./views/adminLayout/Discussion/Add.vue'),
                    meta: {
                        pageTitle: 'Add Discussions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/assignment_subscriptions',
                    name: 'assignment_subscriptions',
                    component: () => import('./views/adminLayout/Assignments/AllAssignmentSubscriptions.vue'),
                    meta: {
                        pageTitle: 'All Assignment subscriptions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/assignment_subscriptions_details/:id',
                    name: 'assignment_subscriptions_details',
                    component: () => import('./views/adminLayout/Assignments/AssignmentSubscriptionsDetail.vue'),
                    meta: {
                        pageTitle: 'Assignment Details',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/quiz_subscriptions',
                    name: 'quiz_subscriptions',
                    component: () => import('./views/adminLayout/quizzes/AllQuizSubscriptions.vue'),
                    meta: {
                        pageTitle: 'All Quiz Subscriptions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/quiz_subscriptions_details/:id',
                    name: 'quiz_subscriptions_details',
                    component: () => import('./views/adminLayout/quizzes/QuizSubscriptionsDetail.vue'),
                    meta: {
                        pageTitle: 'Quiz Subscriptions Details',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/assignment_materials',
                    name: 'assignment_materials',
                    component: () => import('./views/adminLayout/Assignments/AssignmentMaterials.vue'),
                    meta: {
                        pageTitle: 'Assignment Materials',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/quiz_materials',
                    name: 'quiz_materials',
                    component: () => import('./views/adminLayout/quizzes/QuizMaterials.vue'),
                    meta: {
                        pageTitle: 'Quiz Materials',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/sections',
                    name: 'sections',
                    component: () => import('./views/adminLayout/Sections/All.vue'),
                    meta: {
                        pageTitle: 'All Sections',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/topics',
                    name: 'course_topics',
                    component: () => import('./views/adminLayout/PrivateSessions/CourseTopics.vue'),
                    meta: {
                        pageTitle: 'Course Topics',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/sessions_subscriptions',
                    name: 'course_sessions_subscriptions',
                    component: () => import('./views/adminLayout/PrivateSessions/CourseSessionSubscriptions.vue'),
                    meta: {
                        pageTitle: 'Course Sessions Subscriptions',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/show_student_assignment/:id',
                    name: 'show_student_assignment',
                    component: () => import('./views/adminLayout/Assignments/AllStudentAssignments.vue'),
                    meta: {
                        pageTitle: 'All Student Assignments',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_teachers',
                    name: 'all_teachers',
                    component: () => import('./views/adminLayout/Teachers/All.vue'),
                    meta: {
                        pageTitle: 'All Teachers',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_teachers/add',
                    name: 'add_teachers',
                    component: () => import('./views/adminLayout/Teachers/Add.vue'),
                    meta: {
                        pageTitle: 'Add Teacher',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_teachers/edit/:id',
                    name: 'edit_teacher',
                    component: () => import('./views/adminLayout/Teachers/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Teachers',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_roles',
                    name: 'all_roles',
                    component: () => import('./views/adminLayout/Roles/All.vue'),
                    meta: {
                        pageTitle: 'All Roles',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_roles/add',
                    name: 'add_roles',
                    component: () => import('./views/adminLayout/Roles/Add.vue'),
                    meta: {
                        pageTitle: 'Add Roles',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_roles/edit/:id',
                    name: 'edit_roles',
                    component: () => import('./views/adminLayout/Roles/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Roles',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_groups',
                    name: 'all_groups',
                    component: () => import('./views/adminLayout/Groups/All.vue'),
                    meta: {
                        pageTitle: 'All Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_groups/add/:id',
                    name: 'add_groups',
                    component: () => import('./views/adminLayout/Groups/Add.vue'),
                    meta: {
                        pageTitle: 'Add Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_groups/edit/:id',
                    name: 'edit_groups',
                    component: () => import('./views/adminLayout/Groups/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Groups',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_courses',
                    name: 'all_courses',
                    component: () => import('./views/adminLayout/Courses/All.vue'),
                    meta: {
                        pageTitle: 'All Courses',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/all_courses/add/:list_id',
                    name: 'add_courses',
                    component: () => import('./views/adminLayout/Courses/Add.vue'),
                    meta: {
                        pageTitle: 'Add Courses',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_courses/edit/:list_id',
                    name: 'edit_courses',
                    component: () => import('./views/adminLayout/Courses/Edit.vue'),
                    meta: {
                        pageTitle: 'Edit Courses',
                        authRequired: true,
                        isCommon: true,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/all_users',
                    name: 'all_users',
                    component: () => import('./views/adminLayout/Users/All.vue'),
                    meta: {
                        pageTitle: 'All Users',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/main_settings',
                    name: 'main_settings',
                    component: () => import('./views/adminLayout/Settings/settings.vue'),
                    meta: {
                        pageTitle: 'Main Settings',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/discounts',
                    name: 'getAllDiscounts',
                    component: () => import('./views/adminLayout/Discounts/All.vue'),
                    meta: {
                        pageTitle: 'getAllDiscounts',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/discounts/add',
                    name: 'addDiscount',
                    component: () => import('./views/adminLayout/Discounts/Add.vue'),
                    meta: {
                        pageTitle: 'addDiscount',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/reports/getAllUsersReport',
                    name: 'getAllUsersReport',
                    component: () => import('./views/adminLayout/Reports/getAllUsersReport.vue'),
                    meta: {
                        pageTitle: 'getAllUsersReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/reports/getAllCoursesReport',
                    name: 'getAllCoursesReport',
                    component: () => import('./views/adminLayout/Reports/getAllCoursesReport.vue'),
                    meta: {
                        pageTitle: 'getAllCoursesReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/reports/getAllCoursesReport/:id',
                    name: 'getCourseReport',
                    component: () => import('./views/adminLayout/Reports/getCourseReport.vue'),
                    meta: {
                        pageTitle: 'getCourseReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },


                {
                    path: '/admin/reports/getAllUniversitiesReport',
                    name: 'getAllUniversitiesReport',
                    component: () => import('./views/adminLayout/Reports/getAllUniversitiesReport.vue'),
                    meta: {
                        pageTitle: 'getAllUniversitiesReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },

                {
                    path: '/admin/reports/getAllListReport',
                    name: 'getAllListReport',
                    component: () => import('./views/adminLayout/Reports/getAllListReport.vue'),
                    meta: {
                        pageTitle: 'getAllListReport',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/board',
                    name: 'board',
                    component: () => import('./views/adminLayout/board/All.vue'),
                    meta: {
                        pageTitle: 'Board',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/ads',
                    name: 'ads',
                    component: () => import('./views/adminLayout/ads/All.vue'),
                    meta: {
                        pageTitle: 'Ads',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/links',
                    name: 'links',
                    component: () => import('./views/adminLayout/links/All.vue'),
                    meta: {
                        pageTitle: 'Links',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },
                {
                    path: '/admin/videos',
                    name: 'videos',
                    component: () => import('./views/adminLayout/videos/All.vue'),
                    meta: {
                        pageTitle: 'Videos',
                        authRequired: true,
                        isCommon: false,
                        isAdmin: true,
                    }
                },


                // {
                //     path: '/teacher/dashboard',
                //     name: 'teacher_dashboard',
                //     component: () => import('./views/teacherLayout/Dashboard.vue'),
                //     meta: {
                //         pageTitle: 'Teacher Dashboard',
                //         authRequired: false,
                //         isAdmin: false,
                //     }
                // },
                // {
                //     path: '/teacher/courses/:id/lessons',
                //     name: 'teacher_dashboard_courses',
                //     component: () => import('./views/teacherLayout/myCourse/Courses.vue'),
                //     meta: {
                //         pageTitle: 'Teacher Dashboard',
                //         authRequired: false,
                //         isAdmin: false,
                //     }
                // },
                // {
                //     path: '/teacher/courses/:id/discussions',
                //     name: 'teacher_dashboard_discussions',
                //     component: () => import('./views/teacherLayout/myCourse/Discussions.vue'),
                //     meta: {
                //         pageTitle: 'Teacher Dashboard',
                //         authRequired: false,
                //         isAdmin: false,
                //     }
                // },
                // {
                //     path: '/teacher/courses/:id/assignments',
                //     name: 'teacher_dashboard_assignments',
                //     component: () => import('./views/teacherLayout/myCourse/Assignments.vue'),
                //     meta: {
                //         pageTitle: 'Teacher Dashboard',
                //         authRequired: false,
                //         isAdmin: false,
                //     }
                // },
                // {
                //     path: '/teacher/courses/:id/students',
                //     name: 'teacher_dashboard_students',
                //     component: () => import('./views/teacherLayout/myCourse/Students.vue'),
                //     meta: {
                //         pageTitle: 'Teacher Dashboard',
                //         authRequired: false,
                //         isAdmin: false,
                //     }
                // },
            ],
        },
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/Error404'
        }
    ],
})

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('preloader');
    if (appLoading) {
        setTimeout(() => {
            appLoading.style.display = "none";
        }, 1000);
    }
})

router.beforeEach((to, from, next) => {
    // If auth required, check login. If login fails redirect to login page
    try {
        let token = localStorage.getItem("token");
        let tokenAdmin = localStorage.getItem("tokenAdmin");
        document.title = to.meta.pageTitle;

     if (to.meta.authRequired) {
        if (to.meta.isAdmin) {
            if (!tokenAdmin) {
                router.push({ name: 'login_admin', query: { to: to.path } }).catch((erorr)=>{console.log(erorr)});
                // return;
            }
        } else {

            if (!token)
            router.push({ name: 'login', query: { to: to.path } }).catch((erorr)=>{console.log(erorr)});
        }
    }

    next();
} catch (error) {
    console.error(error);
}
});

export default router
