<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('sections') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center">{{ $t('sections') }}</h2>

          <div class="text-right row mx-1 my-2 mt-4 d-flex justify-content-end align-items-end">
            <button class="btn btn-danger-light mx-2" @click="showAddModal()">{{ $t('add_section') }}</button>
            <button class="btn btn-success-light mx-2" @click="isDraggable = !isDraggable" v-if="!isDraggable"><i class="ti-move"></i> {{ $t('reorder') }}</button>
            <button class="btn btn-success-light mx-2" @click="saveOrder()" v-if="isDraggable"><i class="ti-save"></i> {{ $t('save_order') }}</button>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
                <table class="table">
                  <thead class="thead-default">
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">{{ $t('name_ar') }}</th>
                    <th class="text-left">{{ $t('name_en') }}</th>
                    <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                  </tr>
                  </thead>
                  <draggable v-model="sections" tag="tbody" item-key="id" :disabled="!isDraggable">
                    <tr v-for="(section , index) in sections" :key="index" :class="isDraggable ? 'cursor-move' : ''">
                      <td v-if="isDraggable"><i class="ti-move"></i></td>
                      <td v-else>{{ index + 1 }}</td>
                      <td>{{ section.name_ar }}</td>
                      <td>{{ section.name_en }}</td>
                      <td>
                        <div class="btn-group direction-inverse">
                          <button class="btn custom_btn btn-primary-light" @click="deleteSection(section.id)">
                            {{ $t('delete') }}
                          </button>
                          <button class="btn custom_btn btn-success-light" @click="showEditModal(section)">
                            {{ $t('edit') }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </draggable>
                </table>
              <p class="text-lg-center font-weight-bold" v-if="sections.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addEditSectionRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ modalType === 'add' ? 'إضافة قسم جديد' : 'تعديل قسم' }}</h4>
      <div class="row text-left">
        <div class="col-12">
          <label class="font-weight-bold">{{ $t('name_ar') }}</label>
          <input type="text" v-validate="'required'" v-model="newSection.name_ar" class="form-control" :placeholder="$t('name_ar')">
        </div>
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('name_en') }}</label>
          <input type="url" v-validate="'required'" v-model="newSection.name_en" class="form-control" :placeholder="$t('name_en')">
        </div>
        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addEditSection()">
            {{ $t(modalType) }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import { SweetModal } from "sweet-modal-vue";
import draggable from "vuedraggable";

export default {
  name: "All",
  components: { draggable, SweetModal },
  data() {
    return {
      sections: [],
      modalType: 'add',
      updateSectionId: -1,
      error: "",
      newSection: {
        name_ar: "",
        name_en: ""
      },
      isDraggable: false,
    }
  },
  mounted() {
    this.getAllSections();
  },
  methods: {
    showAddModal() {
      this.modalType = 'add';
      this.$refs.addEditSectionRef.open();
    },
    showEditModal(section) {
      this.modalType = 'edit';

      this.newSection = {
        name_ar: section.name_ar || '',
        name_en: section.name_en || '',
      };

      this.updateSectionId = section.id;
      this.$refs.addEditSectionRef.open();
    },
    async addEditSection() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        let response;
        const data = {
          "name_ar": this.newSection.name_ar,
          "name_en": this.newSection.name_en,
        };

        if (this.modalType === 'add') {
          response = await this.$store.dispatch("moduleList/addSection", { data }).catch((error) => {
            this.$helper.handleError(error, this);
          });

        } else if (this.modalType === 'edit' && this.updateSectionId) {
          const payload = {
            data: [{ ...data, id: this.updateSectionId }],
          };

          response = await this.$store.dispatch("moduleList/updateSections", payload).catch((error) => {
            this.$helper.handleError(error, this);
          });
        }

        if (response.status === 200 || response.status === 201) {
          this.$helper.showMessage('success', this);
        }

        this.$refs.addEditSectionRef.close();
        this.newSection = {
          name_en: "",
          name_ar: ""
        };

        await this.getAllSections();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
    async saveOrder() {
      const payload = {
        data: [],
      };

      this.sections.forEach((value, index) => {
        payload.data.push({ id: value.id, order: index });
      });

      const response = await this.$store.dispatch("moduleList/updateSections", payload).catch((error) => {
        this.$helper.handleError(error, this);
      });

      if (response.status === 200) {
        this.$helper.showMessage('success', this);
        this.isDraggable = false;
      }
    },
    getAllSections() {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch('moduleList/getAllSections').then(res => {
        vm.sections = res.data.data;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    deleteSection(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.$helper.showLoader();
          this.$store.dispatch("moduleList/deleteSection", { id })
              .then(value => {
                vm.$helper.hideLoader();
                vm.$helper.showMessage('success', vm, 'تم حذف القسم');
                vm.sections = cloneDeep(vm.sections).filter(section => section.id != id);
              })
              .catch((error) => {
                vm.$helper.hideLoader();
                vm.$helper.handleError(error, vm);
              });
        }
      });
    },
  },
};
</script>

<style>
.multiselect__select {
  height: 55px;
}

.multiselect__tags {
  min-height: 55px;
  padding: 15px 40px 0 8px;
}
</style>
