<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click="$router.back()">{{ $t('courses') }}</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ courseName }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('private_sessions') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row p-2">
            <div class="col-12 pt-3 text-center">
              <h2 class="font-weight-bold fun_font">{{ $t('private_sessions') }}</h2>
              <p>المحاضرات الخاصة المتاحة للإشتراك بهذه الدورة</p>
            </div>
          </div>

          <div class="text-right row mx-1 my-2 d-flex justify-content-end align-items-end">
            <button class="btn btn-danger-light mx-2" @click="showAddModal()">
              {{ $t('add_lecture') }}
            </button>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name_ar') }}</th>
                  <th class="text-left">{{ $t('name_en') }}</th>
                  <th class="text-left">{{ $t('type') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(topic , index) in courseTopics" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ topic.name_ar }}</td>
                  <td>{{ topic.name_en }}</td>
                  <td><h6 class="badge badge-secondary">{{ $t(topic.type) }}</h6></td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button class="btn custom_btn btn-primary-light" @click="deleteTopic(topic.id)">
                        {{ $t('delete') }}
                      </button>
                      <button class="btn custom_btn btn-success-light" @click="showEditModal(topic)">
                        {{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="courseTopics.length === 0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addEditTopicRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ modalType === 'add' ? 'إضافة محاضره جديده' : 'تعديل محاضره' }}</h4>
      <div class="row text-left mt-4">
        <div class="col-md-6">
          <label class="font-weight-bold">{{ $t('name_ar') }}</label>
          <input type="text" v-validate="'required'" v-model="newTopic.name_ar" class="form-control" :placeholder="$t('name_ar')">
        </div>
        <div class="col-md-6 mt-md-0 mt-3">
          <label class="font-weight-bold">{{ $t('name_en') }}</label>
          <input type="url" v-validate="'required'" v-model="newTopic.name_en" class="form-control" :placeholder="$t('name_en')">
        </div>
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('type') }}</label>
          <select v-validate="'required'" data-vv-name="type" v-model="newTopic.type" class="form-control">
            <option
                :key="index" v-for="(type , index) in topicTypes" :value="type">{{ $t(type) }}
            </option>
          </select>
        </div>
        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addEditTopic()">
            {{ $t(modalType) }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import helper from "../../../helpers/helper";
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "All",
  components: { SweetModal },
  data() {
    return {
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.name,
      courseTopics: [],
      topicTypes: ['midterm', 'final', 'other'],
      modalType: 'add',
      updateTopicId: -1,
      error: "",
      newTopic: {
        name_ar: "",
        name_en: "",
        type: "",
      },
    }
  },
  mounted() {
    this.getAllTopics();
  },
  methods: {
    getAllTopics() {
      let vm = this;
      if (!vm.courseId) {
        helper.showMessage('error', vm, 'برجاء تحديد الدورة لتحميل المحاضرات');
        return;
      }

      vm.$helper.showLoader();
      this.$store.dispatch("moduleList/getCourseTopics", { courseId: vm.courseId })
          .then(res => {
            vm.courseTopics = res?.data?.data?.data || [];
            vm.$helper.hideLoader();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    deleteTopic(topicId) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(topicId);
        }
      });
    },
    deleteRequest(topicId) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleList/deleteCourseTopic", { courseId: vm.courseId, topicId })
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم الحذف بنجاح');
            vm.courseTopics = cloneDeep(vm.courseTopics).filter(topic => +topic.id !== +topicId);
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },
    showAddModal() {
      this.modalType = 'add';
      this.newTopic = {
        name_en: "",
        name_ar: "",
        type: ""
      };
      this.$refs.addEditTopicRef.open();
    },
    showEditModal(topic) {
      this.modalType = 'edit';

      this.newTopic = {
        name_ar: topic.name_ar || '',
        name_en: topic.name_en || '',
        type: topic.type || '',
      };

      this.updateTopicId = topic.id;
      this.$refs.addEditTopicRef.open();
    },
    async addEditTopic() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        let response;

        if (this.modalType === 'add') {
          response = await this.$store.dispatch("moduleList/addCourseTopic", { data: this.newTopic, courseId: this.courseId }).catch((error) => {
            this.$helper.handleError(error, this);
          });

        } else if (this.modalType === 'edit' && this.updateTopicId) {
          const payload = {
            data: this.newTopic,
            courseId: this.courseId,
            topicId: this.updateTopicId,
          };

          response = await this.$store.dispatch("moduleList/updateCourseTopic", payload).catch((error) => {
            this.$helper.handleError(error, this);
          });
        }

        if (response.status === 200 || response.status === 201) {
          this.$helper.showMessage('success', this);
        }

        this.$refs.addEditTopicRef.close();
        this.newTopic = {
          name_en: "",
          name_ar: "",
          type: ""
        };

        await this.getAllTopics();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
  },
};
</script>
