<template>
    <div>
        <div class="image-cover ed_detail_head lg theme-ov" style="background:#f4f4f4;"
             :style="{backgroundImage:'url('+require('@/assets/img/banner-2.jpg')+')'}" data-overlay="9">
            <div class="container">
                <div class="row direction">

                    <div class="col-lg-7 col-md-9">
                        <div class="ed_detail_wrap light">
                            <div class="ed_header_caption">
                                <h1 class="ed_title font-weight-bold fun_font">{{$t('new_account')}}</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="bg-light pt-0">
            <div class="container">
                <div class="row direction">
                    <div class="col-md-3"></div>
                    <div class="col-lg-6 col-md-6">
                        <div class="ed_view_box style_2 overlio">

                            <div class="ed_author direction">
                                <div class="ed_author_box">
                                    <h2 class="font-weight-bold fun_font" v-if="!success_register">{{$t('new_account')}}</h2>
                                    <h2 class="font-weight-bold fun_font" v-if="success_register">{{$t('new_account_verify')}}</h2>
                                </div>
                            </div>

                            <div class="ed_view_price pl-4 pr-4 direction" v-if="!success_register">
                                <div class="form-group">
                                    <label>{{$t('name')}}<i class="text-danger">*</i></label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="name" v-model="name"
                                           class="form-control"
                                           :placeholder="$t('name')">
                                    <span class="text-danger text-sm">{{ errors.first('name') }}</span>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{$t('email')}}<i class="text-danger">*</i></label>
                                            <input type="text" data-vv-name="email" v-model="email"
                                                   class="form-control"  v-validate="'required'"
                                                   :placeholder="$t('email')">
                                            <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">

                                        <div class="form-group">
                                            <label>{{$t('phone')}}<i class="text-danger">*</i></label>
                                            <input type="text"
                                                   v-validate="'required'" data-vv-name="mobile" v-model="mobile"
                                                   class="form-control"
                                                   :placeholder="$t('phone')">
                                            <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>
                                        </div>
                                    </div>
                                </div>
                                 <div class="form-group">
                                    <label>{{$t('username')}}<i class="text-danger">*</i></label>
                                    <input type="text"
                                           v-validate="'required'" data-vv-name="username" v-model="username"
                                           class="form-control"
                                           :placeholder="$t('username')">
                                    <span class="text-danger text-sm">{{ errors.first('username') }}</span>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{$t('password')}}<i class="text-danger">*</i></label>
                                            <input v-validate="'required'" v-model="password" data-vv-name="password"
                                                   type="password"
                                                   class="form-control"
                                                   placeholder="*******">
                                            <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>{{$t('password_confirmation')}}<i class="text-danger">*</i></label>
                                            <input v-validate="'required'" v-model="password_confirmation"
                                                   data-vv-name="password_confirmation"
                                                   type="password"
                                                   class="form-control"
                                                   placeholder="*******">
                                            <span class="text-danger text-sm" id="pass_confirm">{{ errors.first('password_confirmation') }}</span>
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-12 mb-2">
                                        <label>{{$t('bio')}}</label>
                                        <vue-editor dir="ltr" v-model="bio"></vue-editor>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <vue-recaptcha class="m-auto" :loadRecaptchaScript="true"
                                                           @verify="onCaptchaVerified"
                                                           :sitekey="getCaptchaKey()"></vue-recaptcha>
                                            <span class="text-danger text-sm">{{ errors.first('recaptchaToken') }}</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>

                            <div class="col-md-12 text-center">
                <span class="flex-d text-danger text-sm" id="error_handle"
                  ></span
                >
              </div>
                            <div class="ed_view_link">
                                <a href="#" class="btn btn-theme enroll-btn" v-if="!success_register"
                                    @click.prevent="submitForm()">{{$t('register')}}
                                </a>
                    <div class="row m-1 mt-4" v-if="success_register">
                        <div class="col-12"><p>{{$t('new_account_verify_message')}}</p></div>
                <div class="col-9 mt-4">
                    <!--   v-validate="'required'" data-vv-name="secret_code" -->
                  <label>{{$t('secret_code')}}<i class="text-danger">*</i></label>
                                    <input type="number"
                                          v-model="code" data-vv-name="code"
                                           class="form-control"
                                           :placeholder="$t('secret_code')">
                                    <span class="text-danger text-sm" id="secret_code">{{ errors.first('code') }}</span>
                                
                </div>
                <div class="col-3 mt-2">
                  <button
                    class="btn btn-success-light mt-5"
                    @click="verifySecretCode()"
                  >{{$t('verify')}}</button>
                </div>
              </div>    
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!-- <sweet-modal ref="verifyAccount" style="">
      
    </sweet-modal> -->
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
 
    export default {
        name: "Regsiter",
        components: {VueRecaptcha,SweetModal,SweetModalTab},
        data() {
            return {
                name: null,
                username:'',
                email:null,//'sadasds@jj.com',//
                mobile: null,
                password: null,
                password_confirmation: null,
                selectedCounrty: null,
                bio: null,
                gender: 'male',
                countries: [],
                code:'',
                success_register:false,
                captcha: null
            }
        },
        mounted() {
            if(this.$helper.isInstructor())
              this.$router.push({name:'teacher_dashboard'}).catch(()=>{});
         },
        methods: {
            showModalSecret() {
            // this.$refs.verifyAccount.open();
            this.success_register=true;
            },
            closeModalSecret() {
            this.$refs.verifyAccount.close();
            },
            nameWithLang(o) {
                return o.translated.title;
            },
            getCaptchaKey() {
                return process.env.VUE_APP_GOOGLE_CAPATCHA
            },
            onCaptchaVerified(recaptchaToken) {
                this.captcha = recaptchaToken;
            },
           
            submitForm() {
                let vm=this;
                    //check if password is similar
                     if( vm.password !=  vm.password_confirmation)
                     {
                        $('#pass_confirm').text(this.$t('password_confirmation_note'));
                        return
                     }

                this.$validator.validateAll().then(result => {
                    
                    if (result) {
                        // if form have no errors
                      
                        this.registerJWT();
                        
                    } else {
                        // form have validation errors
                    $('html, body').animate({ scrollTop: 19 }, 'slow');
                    this.$helper.printValidationError(this.$validator.errors,this);
                    }
                })
            },
            registerJWT() {
                let vm = this;
                vm.$helper.showLoader()
                const payload ={};
                let data= {
                    name_ar: vm.name,
                    name_en: vm.name,
                    username: vm.username,
                    email: vm.email,
                    mobile: vm.mobile,
                    password: vm.password,
                    isVerified:1,
                };


                 const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    },
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        $('#loader_message').text(percentCompleted + ' %')
                    }
                };

                const formData = new FormData();
                _.forEach(data, (value, key) => {
                  if(value!=null)
                    formData.append(key, value)
                });

                payload.data=formData;
                payload.config=config;

                vm.$store.dispatch('moduleTeacher/addTeacher', payload)
                    .then(() => {
                        vm.$helper.hideLoader()
                        vm.$router.push({name:'login'}).catch(()=>{});
                        vm.$helper.showMessage('success',vm);
                        // this.showModalSecret();
                    })
                    .catch(error => {
                        if(error.response){
                          let error_type = error.response.data;
 
                            if (
                                error_type.message === "RESOURCE_CONFLICT" ||
                                error_type.message === "INVALID_PASSWORD"
                            )
                                $("#error_handle").text(
                                vm.$helper.getErrorValueLang(error_type.data)
                                );
                        }
                        vm.$helper.hideLoader()
                        vm.$helper.handleError(error, vm);
                       
                    })
            },
            verifySecretCode(){
                let vm=this;
                if(!vm.code)
                    $('#secret_code').text(this.$t('isRequired'));
                else
                    $('#secret_code').text('');

                let payload={};
                payload.email=vm.email;
                payload.code=vm.code;

                 vm.$store.dispatch('moduleAuth/verifyAccount', payload)
                    .then((response) => {
                        vm.$helper.hideLoader()
                        vm.$helper.showMessage('success',vm,vm.$t('success_msg'))
                         vm.$router.push({name:'login'}).catch(()=>{});
                    })
                    .catch(error => {
                        //in case of error
                        if(error.response.data.message == 'VERIFICATION_CODE_INCORRECT')
                         $('#secret_code').text(vm.$helper.getErrorValueLang(error.response.data.data));
                       else if(error.response.data.message =='JOI_VALIDATION_INVALID_DATA')
                         $('#secret_code').text(vm.$helper.getErrorValueLang(JSON.parse(error.response.data.data[0].message)));
                        vm.$helper.hideLoader()
                       vm.$helper.handleError(error, vm);
                       
                    })
            },
        }
    }
</script>
<style>
    .multiselect__select {
        height: 55px;
    }

    .multiselect__tags {
        min-height: 55px;
        padding: 15px 40px 0 8px;
    }
</style>