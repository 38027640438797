export default {
    BASE: process.env.VUE_APP_API,

    REFRESH_ACCESS_TOKEN: '',

    AUTH_JWT_LOGIN_ADMIN: '/auth/signin',
    AUTH_JWT_Forget_Password: '/auth/forgotPassword',
    AUTH_JWT_Verify_Email_Code: '/auth/sendVerificationCode',
    ALL_ADMINS: 'listUser',
    ADD_ADMIN: 'auth/signup',
    UPDATE_ADMIN: 'updateUser',
    FIND_ADMIN: 'listUserById',
    DELETE_ADMIN: 'deleteUser',
    VERIFY_EMAIL:'auth/verifyEmail',

    ALL_USERS: '/user',
    ADD_USER: '/user/create',
    UPDATE_USER: '/user/update',
    FIND_USER: '/user/find',
    DELETE_USER: '/user/delete',
    LIST_USERS: 'user/listUsers',
    CREATE_NEW_USER: '/user/createUser',
    LIST_ALL_ROLES: '/user/listAllRoles',

    AUTH_JWT_LOGIN_USER: '/auth/signin',
    AUTH_JWT_GET_USER: '/auth/signin',
    AUTH_JWT_UPDATE_PROFILE: '/user/updateProfile',
    AUTH_JWT_UPDATE_PASSWORD: '/common/updatePassword',
    AUTH_JWT_REGISTER_USER: '/user/create',
    CHECK_OUT_LIST: 'user/payList',

    DASHBOARD_STATISTICS: 'report/getReportTotal',
    USERS_REPORT: 'report/getAllUsersReport',

    COMMON_GET_ALL_SETTINGS: 'common/getSetting',
    COMMON_UPDATE_SETTINGS: 'common/updateSetting',
    COMMON_GET_ALL_COUNTRIES: 'common/getCountries',



    CREATE_SUBSCRIBE: 'subscribe/create',
    GET_COURSE_SUBSCRIBES: (id) => `course/${id}/subscriptions`,
    CHANGE_SUBSCRIBE_POINTS: (id) => `subscription/${id}/points`,
    GET_SUBSCRIPTIONS_OF_COURSE: (id) => `subscriptions/${id}`,
    GET_ALL_SUBSCRIPTIONS_REPORT: `statistics/allSubscriptionsReport`,

    GET_ALL_SLIDERS: 'slider/all',
    CREATE_SLIDERS: 'slider/create',
    FIND_SLIDER: 'slider/find',
    UPDATE_SLIDERS: 'slider/update',
    DELETE_SLIDER: 'slider/delete',


    LIST_LESSON_DISSCUSSION:'listLessonDiscussion', //?lessonId=1&doPagination=1&numPerPage=3&page=1
    LIST_LESSON_DISSCUSSION_COMMENTS:'listlessonDiscussionComments', //?lessonId=1&doPagination=1&numPerPage=3&page=1
    LIST_LESSON_DISSCUSSION_COMMENTS_ID:'listlessonDiscussionCommentsById',
    LIST_LESSON_DISSCUSSION_ID:'listlessonDiscussionById',
    ADD_LESSON_DISSCUSSION:'addlessonDiscussionComments',
    UPDATE_LESSON_DISSCUSSION_COMMENT:'updatelessonDiscussionComments',
    UPDATE_LESSON_DISSCUSSION:'updatelessonDiscussion',
    DELETE_LESSON_DISSCUSSION:'deletelessonDiscussion',
    DELETE_LESSON_DISSCUSSION_COMMENT:'deletelessonDiscussionComments',


    LIST_RATING_REVIEW:'listRatingAndReview',
    FIND_RATING_REVIEW_ID:'listRatingAndReviewById',
    LIST_RATING_REVIEW_COURSEID:'listRatingAndReviewByCourseId',
    ADD_RATING_REVIEW:'addRatingAndReview',
    UPDATE_RATING_REVIEW:'updateRatingAndReview',
    DELETE_RATING_REVIEW:'deleteRatingAndReview',

    LIST_ASSIGNMENT_SUBMISSION_ID:'listAssignmentSubmissionById',
    LIST_ASSIGNMENT_SUBMISSION:'listAssignmentsSubmission',
    ADD_ASSIGNMENT_SUBMISSION:'addAssignmentSubmission',
    DELETE_ASSIGNMENT_SUBMISSION:'deleteAssignmentSubmission',
    EVALUTE_ASSIGNMENT_SUBMISSION:'evaluateAssignmentSubmission',
    DELETE_ASSIGNMENT_SUBMISSION_ATTACHEMNT:'deleteAssignmentSubmissionAttachment',
    UPDATE_ASSIGNMENT_SUBMISSION:'updateAssignmentSubmission',
    GET_ASSIGNMENT_ADMIN:'assignments/admin',
    GET_ASSIGNMENT:'assignments',



    GET_ALL_UNIVERSITIES: 'listUniversity',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_UNIVERSITY: 'addUniversity',
    FIND_UNIVERSITY: 'listUniversityById',
    UPDATE_UNIVERSITY: 'updateUniversity',
    DELETE_UNIVERSITY: 'deleteUniversity',

    GET_ALL_FACULTIES: 'listFaculty',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_FACULTY: 'addFaculty',
    FIND_FACULTY: 'listFacultyById',
    UPDATE_FACULTY: 'updateFaculty',
    DELETE_FACULTY: 'deleteFaculty',

    GET_ALL_LESSON_CATEGORIES: 'categories',

    GET_ALL_COURSES: 'listLesson',//?doPagination=1&numPerPage=1&page=1&searchKey=&method=both&startFrom=1992-11-05 00:00:00.000&startTo=2000-11-05 23:59:59.000
    GET_ALL_LESSONS_FOR_REORDER: 'listLessonsForAvailabilityAndPriority',
    CHANGE_LESSON_ORDER_AVAILABILITY: 'changeLessonAvailabilityAndPriority',
    CREATE_COURSE: 'addLesson',
    FIND_COURSE: 'listLessonById',
    UPDATE_COURSE: 'updateLesson',
    DELETE_COURSES: 'deleteLesson',
    DELETE_COURSE_ATTACHEMENT: 'deleteLessonAttachment',
    DELETE_OTHER_COURSE_ATTACHEMENT: 'otherAttachment',



    GET_ALL_CATEGORIES: 'listDepartment',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_CATEGORY: 'addDepartment',
    FIND_CATEGORY: 'listDepartmentById',
    UPDATE_CATEGORY: 'updateDepartment',
    DELETE_CATEGORY: 'deleteDepartment',



    GET_ALL_SUBJECTS: 'listSubject',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_SUBJECT: 'addSubject',
    FIND_SUBJECT: 'listSubjectById',
    UPDATE_SUBJECT: 'updateSubject',
    DELETE_SUBJECT: 'deleteSubject',


    CREATE_GROUP: 'addGroup',
    FIND_GROUP_COURSEID: 'listGroupByCourseId',
    FIND_GROUP: 'listGroupById',
    UPDATE_GROUP: 'updateGroup',
    DELETE_GROUP: 'deleteGroup',

    GET_ALL_ACADEMIC_YEAR: 'listAcademicYear',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_ACADEMIC_YEAR: 'addAcademicYear',
    FIND_ACADEMIC_YEAR: 'listAcademicYearById',
    UPDATE_ACADEMIC_YEAR: 'updateAcademicYear',
    DELETE_ACADEMIC_YEAR: 'deleteAcademicYear',

    GET_ALL_TEACHER: 'listInstructor',//doPagination=0&numPerPage=10&page=1&searchKey=
    GET_INSTRUCTOR_COURSES: 'listInstructorCourses',//doPagination=0&numPerPage=10&page=1&searchKey=
    CREATE_TEACHER: 'addInstructor',
    NOTIFY_SUBSCRIBERS: 'notifySubscribers',
    NOTIFY_USERS: 'notifyUsers',
    GET_BOARD: 'board',
    GET_ADS: 'a_d_s',
    GET_LINKS_ADMIN: 'getLinks',
    GET_LINKS: 'links',
    GET_VIDEOS: 'videos',
    FIND_TEACHER: 'listInstructorById',
    UPDATE_TEACHER: 'updateInstructor',
    DELETE_TEACHER: 'deleteInstructor',

    // GET_ALL_LIST: 'listCourse',
    GET_ALL_LIST: 'listCourseOriginal',
    GET_ALL_LIST_ByInstructor:'listCourseNoDateByInstructor',
    FIND_LIST: 'listCourseById',
    CREATE_LIST: 'addCourse',
    UPDATE_LIST: 'updateCourse',
    DELETE_LIST: 'deleteCourse',

    GET_ALL_USER_LIST: 'user/getUserLists',

    ADD_ROLES: 'admin/role/create-update',
    ALL_ROLES: 'admin/role',
    ALL_PERMISSIONS: 'admin/role/permissions',
    DELETE_ROLE: 'admin/role/delete',
    FIND_ROLES: 'admin/role',
    UPDATE_ROLES: 'admin/role/create-update',

    ADD_COUPON: 'addCoupon',
    GET_COUPONS: 'coupons',
    DELETE_COUPON: 'coupon',

    GET_DISCOUNTS: 'discounts',
    ADD_DISCOUNT: 'discounts/add',
    DELETE_DISCOUNT: 'discounts/delete',

    GET_MARKETERS: 'marketers',

    GET_SEMESTERS: 'semesters',
    GET_SECTIONS: 'courses/getSectionsV2',
    ADD_SECTION: 'courses/sections/add',
    UPDATE_SECTIONS: 'courses/sections/update',
    DELETE_SECTION: (id) => `courses/sections/${id}/delete`,
    COURSE_TOPICS: (courseId) => `private-sessions/${courseId}/topics`,
    UPDATE_COURSE_TOPIC: (courseId, topicId) => `private-sessions/${courseId}/topics/${topicId}`,
    DELETE_COURSE_TOPIC: (courseId, topicId) => `private-sessions/${courseId}/topics/${topicId}`,
    COURSE_SESSIONS_SUBSCRIPTIONS: `private-sessions/admin`,
    UPDATE_SESSION_TOPIC: (privateSessionId, sessionTopicId) => `private-sessions/${privateSessionId}/sessionTopic/${sessionTopicId}`,
    GET_HOMEWORK_LIST: 'homeworkList',
    HOMEWORKS_LIST: 'homeworks',
    GET_QUIZ_LIST: 'quizList',
    QUIZZES_LIST: 'quizzes',

    CONFIGS: 'configs',
}
