import { render, staticRenderFns } from "./notify_users.vue?vue&type=template&id=a691ae14&scoped=true&"
import script from "./notify_users.vue?vue&type=script&lang=js&"
export * from "./notify_users.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a691ae14",
  null
  
)

export default component.exports