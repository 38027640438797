import axios from "@/axios.js"
import requests from "@/requests.js"

export default {
    getAllTeachers({commit}, filters) {
        return new Promise((resolve, reject) => {
            axios.get(requests.GET_ALL_TEACHER, {
                params: filters
            }).then((response) => {
                if (response.data.status) {
                    commit('SET_TEACHERS', response.data.data.result.data)
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    getInstructorCourses({commit}, payload, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.GET_INSTRUCTOR_COURSES}?id=${payload.id}`, payload.config).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    addTeacher({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(requests.CREATE_TEACHER, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    notifySubscribers({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.NOTIFY_SUBSCRIBERS}/${payload.courseId}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    findTeacher({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`${requests.FIND_TEACHER}/${payload.id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateTeacher({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.put(`${requests.UPDATE_TEACHER}/${payload.id}`, payload.data, payload.config)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    removeTeacher({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`${requests.DELETE_TEACHER}/${payload.id}`, payload)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
}
