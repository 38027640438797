<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li
                class="breadcrumb-item active"
                aria-current="page"
                @click.prevent
            >{{ $t('videos') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="text-center p-4">
            <h2 class="font-weight-bold fun_font">{{ $t('videos') }}</h2>
          </div>

          <div class="mx-3 d-flex justify-content-between">
            <select ref="type" data-vv-name="type" v-model="selectedType" class="form-control w-auto" @change="filterLinks()">
              <option :key="index" v-for="(type , index) in VIDEO_TYPES" :value="type.name"
              >{{ $t(type.text) }}
              </option>
            </select>

            <button class="btn btn-danger-light" @click="showAddModal()">
              {{ $t('add') }}
            </button>
          </div>

          <div class="dashboard_container_body mt-3">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('title') }}</th>
                  <th class="text-left">{{ $t('category') }}</th>
                  <th class="text-left">{{ $t('adding_date') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in filteredItems" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.category || 'بدون' }}</td>
                  <td>{{ parseDate(item.createdAt) }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button class="btn custom_btn btn-primary-light" @click="deleteItem(item.id)">
                        {{ $t('delete') }}
                      </button>
                      <button class="btn custom_btn btn-success-light" @click="displayVideo(item.video)">
                        {{ $t('video') }}
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="filteredItems.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="playerModal" class="p-0" overlay-theme="dark" modal-theme="dark">
      <div class="container">
        <div class="row">
          <div class="col-mds-12 w-100" v-if="videoID">
            <vue-vimeo-player ref="player" :video-id="videoID" :player-height="300" @ready="onReady"
                              style="width: 100%">
            </vue-vimeo-player>
          </div>
        </div>
      </div>
    </sweet-modal>

    <sweet-modal ref="addVideoRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">إضافة فيديو جديد</h4>
      <div class="row text-left">
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('title') }}</label>
          <input type="text" v-validate="'required'" v-model="newVideo.title" class="form-control" :placeholder="$t('title')">
        </div>
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('video') }}</label>
          <input type="file" accept="video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/x-flv"
                 ref="myVideo"
                 v-validate="'required'"
                 @change="setVideo()"
                 class="form-control"
                 :placeholder="$t('video')">
        </div>
        <div class="col-12 mt-3">
          <label class="font-weight-bold">{{ $t('category') }} ({{ $t('optional') }})</label>
          <multiselect
              v-model="newVideo.category"
              :options="videosCategories"
              :select-label="$t('multiselect_choose')"
              :placeholder="$t('none')"
              @tag="addTag"
              :taggable="true"
          ></multiselect>
        </div>
        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addVideo()">
            {{ $t('add') }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import moment from "moment";
import { SweetModal } from "sweet-modal-vue";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  name: "All",
  components: { SweetModal, vueVimeoPlayer },
  data() {
    return {
      error: "",
      videoID: "",
      newVideo: {
        title: "",
        category: "",
        video: null,
      },
      filteredItems: [],
      allItems: [],
      videosCategories: [],
      VIDEO_TYPES: [{
        name: 'helper',
        text: 'الفيديوهات الاسترشادية'
      }],
      selectedType: 'helper',
      playerReady: false,
    }
  },
  mounted() {
    this.getVideos();
    this.getVideosCategories();
  },
  methods: {
    parseDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return ' - ';
    },
    setVideo() {
      this.newVideo.video = this.$refs.myVideo.files[0];
    },
    showAddModal() {
      this.$refs.addVideoRef.open();
    },
    onSelectedType(o) {
      this.newVideo.category = o;
    },
    addTag (newTag) {
      this.videosCategories.push(newTag);
      this.newVideo.category = newTag;
    },
    onReady() {
      this.playerReady = true
    },
    async getVideos() {
      let vm = this;
      vm.$helper.showLoader();

      const res = await this.$store.dispatch("moduleAdmin/getVideos", { type: vm.selectedType }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data && res.data.data.length) {
        vm.allItems = res.data.data;
        this.filterItems();
      }
      vm.$helper.hideLoader();
    },

    async getVideosCategories() {
      let vm = this;
      vm.$helper.showLoader();

      const res = await this.$store.dispatch("moduleAdmin/getVideosCategories", { type: vm.selectedType }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });

      if (res.data.status && res.data.data && res.data.data.length) {
        // const none = res.data.data.some(item => (item == null || item.length === 0));
        // vm.videosCategories = none ? res.data.data : res.data.data.concat(['']);
        vm.videosCategories = res.data.data.filter(item => !!item);
      }

      vm.$helper.hideLoader();
    },

    filterItems() {
      this.filteredItems = this.allItems.filter(item => item.type === this.selectedType) || [];
    },

    deleteItem(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.deleteRequest(id);
        }
      });
    },

    deleteRequest(id) {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch("moduleAdmin/removeVideo", { id })
          .then(value => {
            vm.$helper.hideLoader();
            vm.$helper.showMessage('success', vm, 'تم حذف العنصر');
            vm.allItems = cloneDeep(vm.allItems).filter(item => item.id != id);
            this.filterItems();
          })
          .catch((error) => {
            vm.$helper.hideLoader();
            vm.$helper.handleError(error, vm);
          });
    },

    displayVideo(path) {
      this.videoID = path.split('/').pop();

      this.$refs.playerModal.open();
    },

    async addVideo() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        const payload = {};
        payload.config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            $('#loader_message').text(percentCompleted + ' %');
          },
        };
        const formData = new FormData();
        formData.append('title', this.newVideo.title);
        formData.append('type', this.selectedType);
        formData.append('vedio', this.newVideo.video);
        this.newVideo.category && this.newVideo.category.length && formData.append('category', this.newVideo.category);

        payload.data = formData;

        this.$refs.addVideoRef.close();
        this.$helper.showLoader();

        await this.$store.dispatch("moduleAdmin/addVideo", payload).catch((error) => {
          this.$helper.handleError(error, this);
        });

        this.$refs.myVideo.value = "";
        this.newVideo = {
          title: "",
          category: "",
          video: null,
        };
        this.$helper.hideLoader();

        await this.getVideos();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
  },
};
</script>
