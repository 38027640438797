<template>
  <div>
    <Navigation />

  
      <div class="row">
        <div class="col-md-12">
          <div class="card pt-0 mt-0">
            <div class="card-header border-bottom-1">
              <h4 class="font-weight-bold">{{ $t("my_discussion") }} ({{discussions.length}})</h4>
            </div>
            <div class="card-body">
              <div class="p-5 text-center" v-if="discussions.length==0">
                <i class="ti-book fa-3x color--primary"></i>
                <h3>{{ $t("no_data") }}</h3>
              </div>
              <div class="p-1 mr-5">
                <div class="row justify-content-center p-5 mr-5">
                  <div
                    class="col-3"
                    v-if="$helper.isInstructor()"
                  >
                    <button
                      class="btn btn-primary-light"
                      @click="showModalPost()"
                    >
                      {{ $t("add") }} {{ $t("post") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
             <div class="row">
      <div class="col-md-12">
        <div class="post-content" v-for="(item,index) in discussions" :key="index">
          <div class="post-container">
             
            <div class="post-detail">
                 <div class='row'>
                     <div class='col-9'>
              <div class="user-info">
                <img :src="getImage" alt="user" class="profile-photo-md pull-left" />
                <h5>
                  <a href="#" class="profile-link">{{item.user.student?item.user.name:(item.user.instructor?item.user.instructor.name:$t('admin'))}}</a>
                </h5>
                <p
                  class="text-muted"
                >{{$t('from_time')+" "+$helper.diffTwoDates(vm,item.updatedAt).duration +" "+$helper.diffTwoDates(vm,item.updatedAt).msg}}</p>
              </div>

              <div class="line-divider"></div>
              <div class="post-text">
                <p>
                  {{item.text}}
                  <i class="em em-anguished"></i>
                  <i class="em em-anguished"></i>
                  <i class="em em-anguished"></i>
                </p>
              </div>
              <div class="line-divider"></div>
              </div>
              <div class='col-3'>
                    <button 
                      class="btn-sm btn-danger-light"
                      @click="deleteDiscussion(item.id,index)"
                    >{{$t('delete')}} {{$t('post')}}</button>
              </div>
              </div>
              <div
                class="post-comment container"
                v-for="(comment,cindex) in item.lessonDiscussionComments"
                :key="cindex"
              >
                <div class="row w-100">
                  <div class="col-9">
                    <img :src="getImage" alt class="profile-photo-sm" />
                    <!-- <a href="#" class="profile-link ml-2">{{comment.user.student?comment.user.name:(comment.user.instructor.name?comment.user.instructor:$t('admin'))}}</a> -->
                    <p>
                      <i class="em em-laughing"></i>
                      {{comment.text}}
                    </p>
                  </div>
                  <div class="col-3 text-left">
                    <p
                      class="text-muted"
                    >{{$t('from_time')+" "+$helper.diffTwoDates(vm,comment.updatedAt).duration +" "+$helper.diffTwoDates(vm,comment.updatedAt).msg}}</p>
                     <button 
                      class="btn-sm btn-danger-light" 
                      @click="deleteComment(comment.id,index,cindex)"
                    >{{$t('delete')}}</button>
                  </div>
                </div>
              </div>

              <div class="row" v-if="$helper.isInstructor()">
                <div class="col-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="comment[index]"
                    placeholder="Post a comment"
                    data-vv-name="comment"
                    :data-vv-scope="'comment_'+index"
                    v-validate="'required'"
                  />
                  <span
                    :id="'comment_'+index"
                    class="text-danger text-sm"
                  >{{ errors.first('comment') }}</span>
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-success-light"
                    @click="submitForm(item.id,index)"
                  >{{$t('add')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
          </div>
        </div>
      </div>
  <sweet-modal ref="addPost" style="">
      <div class="row">
          
            <div class="col-md-9 form-group">
              <label class="font-weight-bold">{{$t('course')}}</label>
              <multiselect
               :select-label="$t('multiselect_choose')"
                :options="lessons"
                :custom-label="$helper.getTitleValueLang"
                track-by="id"
                v-model="selectedLesson"
                data-vv-name="course"
                v-validate="'required'"
                :placeholder="$t('search')"
              ></multiselect>
              <span class="text-danger text-sm">{{ errors.first('course') }}</span>
            </div>
                <div class="col-9 form-group">
                  <textarea
                    rows="5"
                    class="form-control "
                    v-model="post"
                    placeholder="اكتب نص الرساله"
                    data-vv-name="text"
                    v-validate="'required'"
                    required
                  ></textarea>
                  <span
                    class="text-danger text-sm"
                  >{{ errors.first('text') }}</span>
                </div>
                <div class="col-9 justify-content-center">
                  <button
                    class="btn btn-success-light"
                    @click="addDiscussion()"
                  >{{$t('add')}}</button>
                </div>
              </div>
    </sweet-modal>
 
  </div>
</template>

<script>
import Navigation from "./Navigation";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import moment from "moment";
export default {
  name: "Discussions",
  components: { SweetModal, SweetModalTab, Navigation },
  data() {
    return {
      lists: [],
      selectedList: null,
      teachers: [],
      selectedTeacher: null,
      lessons: [],
      discussions: [],
      selectedLesson: null,
      query: null,
      vm: this,
      comment: [],
      post: "",
      images: [
        "https://bootdey.com/img/Content/avatar/avatar8.png",
        "https://bootdey.com/img/Content/avatar/avatar7.png",
        "https://bootdey.com/img/Content/avatar/avatar6.png",
        "https://bootdey.com/img/Content/avatar/avatar5.png",
        "https://bootdey.com/img/Content/avatar/avatar4.png",
        "https://bootdey.com/img/Content/avatar/avatar3.png",
        "https://bootdey.com/img/Content/avatar/avatar2.png",
        "https://bootdey.com/img/Content/avatar/avatar1.png",
      ],
    };
  },
  computed: {
    getLists: function () {
      return this.lists.filter((item) => {
        if (this.selectedTeacher) {
          if (item.instructor.id == this.selectedTeacher.id) return item;
        } else {
          return item;
        }
      });
    },
    getImage: function () {
      return this.images[Math.floor(Math.random() * this.images.length)];
    },
  },
  mounted() {
    // this.getAllTeachers();
    this.getAllLessons();
    //MMOstsf
    this.getAllLatestDiscussion();
  },
  methods: {
    showModalPost() {
      this.$refs.addPost.open();
    },
    closeModalPost() {
      this.$refs.addPost.close();
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 10000,
        page: 1,
        searchKey: this.query ? this.query : "",
        //1 or 0 or both
      };
    },
    clearListSelection() {
      this.selectedList = null;
    },
    clearLessonSelection() {
      let vm = this;
      vm.selectedLesson = null;
      vm.lessons = vm.selectedList ? vm.selectedList.lessons : [];
    },
    getAllLatestDiscussion() {
      let vm = this;
      let helper = vm.$helper;

      //if this page is allowed
      if (!helper.isInstructor()) {
        helper.showMessage("error", vm, vm.$t("server_error_403"));
        return;
      }

      let data = helper.getLocalStorage("userInfo");

      helper.showLoader();
      let filters = vm.prepareFilters();
      let payload = {};
      payload.filters = filters;
      payload.filters.userId = data ? data.id : "";
      payload.filters.courseId = vm.$route.params ? vm.$route.params.id : "";
      payload.filters.orderBy = "DESC";

      let dispatch = this.$store.dispatch(
        "moduleLessonDiscussion/getAllDisscusion",
        payload
      );
      dispatch
        .then((response) => {
          vm.discussions = this.$store.getters[
            "moduleLessonDiscussion/getAllLessonDiscussion"
          ];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },


      getAllLessons() {
      let vm = this;
      let helper=vm.$helper;
    
        //if this page is allowed
      if(!helper.isInstructor())
      {helper.showMessage('error',vm,vm.$t('server_error_403')); return};

       let data=helper.getLocalStorage('userInfo');
      helper.showLoader();
      let filters = vm.prepareFilters();
      // filters.method = "both";
      let payload = {};
      payload.filters = filters;
      payload.id = vm.$route.params.id;

      let dispatch = this.$store.dispatch(
        "moduleList/findList",
        payload
      );
      dispatch
        .then((response) => {
           let list = response.data.data.course;
           vm.lessons =list ? list.lessons:[]; 
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    // getAllLessons() {
    //   let vm = this;
    //   let course_id = this.selectedList ? this.selectedList.id : "";
    //   vm.$helper.showLoader();
    //   let filters = vm.prepareFilters();
    //   filters.type = "both"; //1 or 0 or both'
    //   filters.course_id = course_id;

    //   let dispatch = this.$store.dispatch(
    //     "moduleCourse/getAllCourses",
    //     filters
    //   );
    //   dispatch
    //     .then((response) => {
    //       vm.lessons = this.$store.getters["moduleCourse/getAllCourses"];
    //       vm.$helper.hideLoader();
    //     })
    //     .catch((error) => {
    //       vm.$helper.handleError(error, vm);
    //       vm.$helper.hideLoader();
    //     });
    // },

    submitForm(lessonDiscussionId, index) {
      this.$validator.validateAll("comment_" + index).then((result) => {
        if (result) {
          // if form have no errors
          this.addComment(lessonDiscussionId, index);
        } else {
          // form have validation errors
          $("#comment_" + index).text(this.$t("isRequired"));
        }
      });
    },
    addComment(lessonDiscussionId, index) {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      payload.data = {};
      if (!lessonDiscussionId) return;

      payload.data.text = vm.comment[index];
      payload.data.userId = vm.$helper.getLocalStorage("userInfo")
        ? vm.$helper.getLocalStorage("userInfo").id
        : "";
      //   payload.data.lessonId = vm.selectedLesson?vm.selectedLesson.id:1;
      payload.data.lessonDiscussionId = lessonDiscussionId;

      vm.$store
        .dispatch("moduleLessonDiscussion/addLessonDiscussion", payload)
        .then((response) => {
          vm.comment[index] = "";
          let comment = response.data.data.comment.comment;
          comment.updatedAt = new moment();
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);

          //   let length=vm.discussions[index].lessonDiscussionComments.length;
          //   this.$set(vm.discussions[index].lessonDiscussionComments,length,comment);

          //prepare data to the array
          let payload = {};
          payload.index = index;
          payload.comment = comment;
          //push data to the array
          this.$store.commit(
            "moduleLessonDiscussion/ADD_LESSON_DISSCUSSION_COMMENT",
            payload
          );
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },

    addDiscussion() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      payload.data = {};

      payload.data.text = vm.post;
      payload.data.userId = vm.$helper.getLocalStorage("userInfo")
        ? vm.$helper.getLocalStorage("userInfo").id
        : "";
      //MMostaf
      payload.data.lessonId = vm.selectedLesson ? vm.selectedLesson.id : "";

      vm.$store
        .dispatch("moduleLessonDiscussion/addLessonDiscussion", payload)
        .then((response) => {
          vm.post = "";
          let discussion = response.data.data.post.post;
          discussion.lessonDiscussionComments = [];
          discussion.updatedAt = new moment();
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
          vm.closeModalPost();
          //push data to the array
          vm.lessons.push(discussion);
          this.$router.go(this.$router.currentRoute)
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },

    deleteComment(lessonDiscussionId, index, cindex) {
      let vm = this;

      vm.$helper.showLoader();
      const payload = {};
      if (!lessonDiscussionId) return;

      payload.id = lessonDiscussionId;

      vm.$store
        .dispatch(
          "moduleLessonDiscussion/removeLessonDiscussionComments",
          payload
        )
        .then(() => {
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);

          //prepare data to the array
          let payload = {};
          payload.index = index;
          payload.cindex = cindex;
          //delete data from the array
          this.$store.commit(
            "moduleLessonDiscussion/REMOVE_LESSON_DISSCUSSION_COMMENT",
            payload
          );
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },
    deleteDiscussion(DiscussionId, index) {
      let vm = this;

      vm.$helper.showLoader();
      const payload = {};
      if (!DiscussionId) return;

      payload.id = DiscussionId;

      vm.$store
        .dispatch("moduleLessonDiscussion/removeLessonDiscussion", payload)
        .then(() => {
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);

          //delete data from the array
          this.$store.commit(
            "moduleLessonDiscussion/REMOVE_LESSON_DISSCUSSION",
            index
          );
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },
  },
};
</script>

<style scoped>
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css";
.post-content {
  background: #f8f8f8;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #f1f2f2;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.post-content img.post-image,
video.post-video,
.google-maps {
  width: 100%;
  height: auto;
}

.post-content .google-maps .map {
  height: 300px;
}

.post-content .post-container {
  padding: 20px;
}

.post-content .post-container .post-detail {
  margin-left: 65px;
  position: relative;
}

.post-content .post-container .post-detail .post-text {
  line-height: 24px;
  margin: 0;
}

.post-content .post-container .post-detail .reaction {
  position: absolute;
  right: 0;
  top: 0;
}

.post-content .post-container .post-detail .post-comment {
  display: inline-flex;
  margin: 10px auto;
  width: 100%;
}

.post-content .post-container .post-detail .post-comment img.profile-photo-sm {
  margin-right: 10px;
}

.post-content .post-container .post-detail .post-comment .form-control {
  height: 30px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 7px 0;
  min-width: 0;
}

img.profile-photo-md {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  float: right;
  margin-left: 20px;
}

img.profile-photo-sm {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.text-green {
  color: #8dc63f;
}

.text-red {
  color: #ef4136;
}

.following {
  color: #8dc63f;
  font-size: 12px;
  margin-left: 20px;
}
</style>