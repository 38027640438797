<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{ $t('dashboard') }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent
            >{{ $t('assignment_materials') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <h2 class="font-weight-bold fun_font text-center pt-2">{{ $t('assignment_materials') }}</h2>

          <div class="text-right row mx-1 my-2 mt-4 d-flex justify-content-between align-items-end">
            <button class="btn btn-danger-light mx-2" @click="showAddModal()">{{ $t('add_material') }}</button>
            <div class="d-flex align-items-end">
              <div class="text-center">
                <label class="font-weight-bold">{{ $t('material_price') }}</label>
                <input type="number" v-model="itemPrice" class="form-control" :placeholder="$t('material_price')" />
              </div>

              <button class="btn btn-success-light mx-2" @click="updatePriceConfig()"><i class="ti-save"></i> {{ $t('save') }}</button>
            </div>
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="thead-default">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left" style="width: 30px">{{ $t('operation') }}</th>
                </tr>
                </thead>
                <tr v-for="(item , index) in lists" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <div class="btn-group direction-inverse">
                      <button class="btn custom_btn btn-primary-light" @click="deleteItem(item.id)">
                        {{ $t('delete') }}
                      </button>
                      <button class="btn custom_btn btn-success-light" @click="showEditModal(item)">
                        {{ $t('edit') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="lists.length==0">{{ $t('no_data') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addEditItemRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ modalType === 'add' ? 'إضافة مقرر جديد' : 'تعديل مقرر' }}</h4>
      <div class="row text-left">
        <div class="col-12">
          <label class="font-weight-bold">{{ $t('name') }}</label>
          <input type="text" v-validate="'required'" v-model="newItemName" class="form-control" :placeholder="$t('name')">
        </div>

        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addEditItem()">
            {{ $t(modalType) }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>

import { cloneDeep } from "lodash";
import { SweetModal } from "sweet-modal-vue";

export default {
  name: "All",
  components: { SweetModal },
  data() {
    return {
      lists: [],
      modalType: 'add',
      error: "",
      newItemName: "",
      updateItemId: -1,
      itemPrice: null,
    }
  },
  mounted() {
    this.getAllList();
    this.getPriceConfig();
  },
  methods: {
    showAddModal() {
      this.modalType = 'add';
      this.newItemName = '';

      this.$refs.addEditItemRef.open();
    },
    showEditModal(item) {
      this.modalType = 'edit';
      this.newItemName = item.name || '';

      this.updateItemId = item.id;
      this.$refs.addEditItemRef.open();
    },
    async addEditItem() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        let response;
        const data = {
          "name": this.newItemName,
        };

        if (this.modalType === 'add') {
          response = await this.$store.dispatch("moduleList/addHomeworkItem", { data }).catch((error) => {
            this.$helper.handleError(error, this);
          });

        } else if (this.modalType === 'edit' && this.updateItemId) {
          const payload = { id: this.updateItemId, data };

          response = await this.$store.dispatch("moduleList/updateHomeworkItem", payload).catch((error) => {
            this.$helper.handleError(error, this);
          });
        }

        if (response.status === 200 || response.status === 201) {
          this.$helper.showMessage('success', this);
        }

        this.$refs.addEditItemRef.close();
        this.newItemName = '';

        await this.getAllList();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
    getAllList() {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch('moduleList/getHomeworkList').then(res => {
        vm.lists = res.data.data;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    deleteItem(id) {
      let vm = this;
      vm.$swal({
        title: vm.$t("warning"),
        text: vm.$t("are_you_sure"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: vm.$t("yes"),
        cancelButtonText: vm.$t("no"),
      }).then((result) => {
        if (result.value) {
          vm.$helper.showLoader();
          this.$store.dispatch("moduleList/deleteHomeworkItem", { id })
              .then(value => {
                vm.$helper.hideLoader();
                vm.$helper.showMessage('success', vm, 'تم حذف المقرر بنجاح');
                vm.lists = cloneDeep(vm.lists).filter(item => item.id != id);
              })
              .catch((error) => {
                vm.$helper.hideLoader();
                vm.$helper.handleError(error, vm);
              });
        }
      });
    },
    getPriceConfig() {
      let vm = this;
      vm.$helper.showLoader();
      this.$store.dispatch('moduleAdmin/getConfig', { event: 'ASSIGNMENT_PRICE' }).then(res => {
        vm.itemPrice = res.data.data.value;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    updatePriceConfig() {
      let vm = this;

      if (!vm.itemPrice || (vm.itemPrice && isNaN(+vm.itemPrice)) || (vm.itemPrice && +vm.itemPrice <= 0)) {
        vm.$helper.showMessage('error', vm, 'يجب اختيار رقم صحيح موجب');
        return;
      }

      vm.$helper.showLoader();

      const payload = {
        event: 'ASSIGNMENT_PRICE',
        data: {
          value: +vm.itemPrice,
        }
      };

      this.$store.dispatch('moduleAdmin/updateConfig', payload).then(res => {
        vm.$helper.showMessage('success', vm);
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
  },
};
</script>