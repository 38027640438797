<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" @click.prevent>{{$t('dashboard')}}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
              @click.prevent
            >{{$t('all_discussions')}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container p-2">
          <div class="row">
            <div class="col-12 p-3 m-2">
              <h2 class="font-weight-bold fun_font text-center">{{$t('all_discussions')}}</h2>
            </div>

            <div class="col-md-4" id="all_teacher_select">
              <label class="font-weight-bold">{{$t('teacher')}}</label>
              <multiselect 
                :options="teachers"
                :custom-label="$helper.getTitleValueLang"
                @input="clearListSelection"
                track-by="id"
                 :select-label="$t('multiselect_choose')"
                v-model="selectedTeacher"
                data-vv-name="instructor"
                :placeholder="$t('search')"
              ></multiselect>
              <span class="text-danger text-sm">{{ errors.first('instructor') }}</span>
            </div>
              <div class="col-md-4">
              <label class="font-weight-bold">{{$t('list')}}</label>
              <multiselect
                :options="getLists"
                :custom-label="$helper.getTitleValueLang"
                @input="clearLessonSelection"
                track-by="id"
                 :select-label="$t('multiselect_choose')"
                v-model="selectedList"
                data-vv-name="list"
                :placeholder="$t('search')"
              ></multiselect>
              <span class="text-danger text-sm">{{ errors.first('list') }}</span>
            </div>
            <div class="col-md-4">
              <label class="font-weight-bold">{{$t('course')}}</label>
              <multiselect
                :options="lessons"
                :custom-label="$helper.getTitleValueLang"
                track-by="id"
                v-model="selectedLesson"
                data-vv-name="course"
                @input="getAllDisscussion"
                :placeholder="$t('search')"
              ></multiselect>
              <span class="text-danger text-sm">{{ errors.first('course') }}</span>
            </div>
          </div>
          <div class="row justify-content-center mt-2">
               <div class="col-3" v-if="$helper.isInstructor() && selectedLesson">
                  <button
                    class="btn btn-success-light"
                    @click="showModalPost()"
                  >{{$t('add')}}  {{$t('post')}}</button>
                </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="post-content" v-for="(item,index) in discussions" :key="index">
          <div class="post-container">
             
            <div class="post-detail">
                 <div class='row'>
                     <div class='col-9'>
              <div class="user-info">
                <img :src="getUserImage(item)" v-if="item.user.student || item.user.instructor" alt="user" class="profile-photo-md pull-left" />
                <img :src="getImage" alt="user" v-else class="profile-photo-md pull-left" />
                <h5>
                  <a href="#" class="profile-link">{{item.user.student?item.user.student.name:(item.user.instructor?$helper.getTitleValueLang(item.user.instructor):$t('admin'))}}</a>
                </h5>
                <p
                  class="text-muted"
                >{{$t('from_time')+" "+$helper.diffTwoDates(vm,item.updatedAt).duration +" "+$helper.diffTwoDates(vm,item.updatedAt).msg}}</p>
              </div>

              <div class="line-divider"></div>
              <div class="post-text">
                <p>
                  {{item.text}}
                  <i class="em em-anguished"></i>
                  <i class="em em-anguished"></i>
                  <i class="em em-anguished"></i>
                </p>
              </div>
              <div class="line-divider"></div>
              </div>
              <div class='col-3'>
                    <button 
                      class="btn-sm btn-danger-light"
                      @click="deleteDiscussion(item.id,index)"
                    >{{$t('delete')}} {{$t('post')}}</button>
              </div>
              </div>
              <div
                class="post-comment container"
                v-for="(comment,cindex) in item.lessonDiscussionComments"
                :key="cindex"
              >
                <div class="row w-100">
                  <div class="col-9">
                     <img :src="getUserImage(comment)" v-if="comment.user.student || comment.user.instructor" alt class="profile-photo-sm" />
                     <img :src="getImage" v-else alt class="profile-photo-sm" />
                    <a href="#" class="profile-link ml-2">{{comment.user.student?comment.user.student.name:(comment.user.instructor?$helper.getTitleValueLang(comment.user.instructor):$t('admin'))}}</a>
                    <p>
                      <i class="em em-laughing"></i>
                      {{comment.text}}
                    </p>
                  </div>
                  <div class="col-3 text-left">
                    <p
                      class="text-muted"
                    >{{$t('from_time')+" "+$helper.diffTwoDates(vm,comment.updatedAt).duration +" "+$helper.diffTwoDates(vm,comment.updatedAt).msg}}</p>
                     <button 
                      class="btn-sm btn-danger-light" 
                      @click="deleteComment(comment.id,index,cindex)"
                    >{{$t('delete')}}</button>
                  </div>
                </div>
              </div>

              <div class="row" v-if="$helper.isInstructor()">
                <div class="col-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="comment[index]"
                    placeholder="Post a comment"
                    data-vv-name="comment"
                    :data-vv-scope="'comment_'+index"
                    v-validate="'required'"
                  />
                  <span
                    :id="'comment_'+index"
                    class="text-danger text-sm"
                  >{{ errors.first('comment') }}</span>
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-success-light"
                    @click="submitForm(item.id,index)"
                  >{{$t('add')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <sweet-modal ref="addPost" style="">
      <div class="row">
                <div class="col-9">
                  <textarea
                    rows="5"
                    class="form-control"
                    v-model="post"
                    placeholder="add a post"
                    data-vv-name="text"
                    v-validate="'required'"
                    required
                  ></textarea>
                  <span
                    class="text-danger text-sm"
                  >{{ errors.first('text') }}</span>
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-success-light"
                    @click="addDiscussion()"
                  >{{$t('add')}}</button>
                </div>
              </div>
    </sweet-modal>
  </div>
</template>

<script>
   import {SweetModal, SweetModalTab} from 'sweet-modal-vue';
   import moment from 'moment';
export default {
    
  name: "All",
   components: {SweetModal, SweetModalTab},
  data() {
    return {
      lists: [],
      selectedList: null,
      teachers: [],
      selectedTeacher: null,
      lessons: [],
      discussions: [],
      selectedLesson: null,
      query: null,
      vm: this,
      comment: [],
      post: '',
      images: [
        "https://bootdey.com/img/Content/avatar/avatar8.png",
        "https://bootdey.com/img/Content/avatar/avatar7.png",
        "https://bootdey.com/img/Content/avatar/avatar6.png",
        "https://bootdey.com/img/Content/avatar/avatar5.png",
        "https://bootdey.com/img/Content/avatar/avatar4.png",
        "https://bootdey.com/img/Content/avatar/avatar3.png",
        "https://bootdey.com/img/Content/avatar/avatar2.png",
        "https://bootdey.com/img/Content/avatar/avatar1.png",
      ],
    };
  },
  computed: {
    getLists: function () {
      return this.lists.filter((item) => {
        if (this.selectedTeacher) {
          if (item.instructor.id == this.selectedTeacher.id) return item;
        } else {
          return item;
        }
      });
    },
    getImage: function () {
      return this.images[Math.floor(Math.random() * this.images.length)];
    },
  },
  mounted() {
    this.getAllTeachers();
    this.getAllLists();
    //MMOstsf
    // this.getAllDisscussion();


    setInterval(()=>{this.getAllLists();} , 4000*60);
  },
  methods: {
    showModalPost() {
        this.$refs.addPost.open()
    },
    getUserImage(item){
     return item.user.student?item.user.student.img:(item.user.instructor?item.user.instructor.img:'');
    },
    closeModalPost() {
        this.$refs.addPost.close();
    },
    prepareFilters() {
      return {
        doPagination: 0,
        numPerPage: 10000,
        page: 1,
        searchKey: this.query ? this.query : "",
        //1 or 0 or both
      };
    },
    clearListSelection() {
      this.selectedList = null;
    },
    clearLessonSelection() {
      let vm = this;
      vm.selectedLesson = null;
      vm.lessons = vm.selectedList ? vm.selectedList.lessons : [];
    },
    getAllTeachers() {
      let vm = this;
      let data=vm.$helper.getLocalStorage("userInfo");

      //check if this page is opened from tracher profile
      if(!vm.$helper.isAdmin()){
        $('#all_teacher_select').hide();
        vm.selectedTeacher={};
        vm.selectedTeacher.id= data?data.instructorId:'';
        return;
      }
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      let dispatch = this.$store.dispatch(
        "moduleTeacher/getAllTeachers",
        filters
      );
      dispatch
        .then((response) => {
          vm.teachers = this.$store.getters["moduleTeacher/getAllTeachers"];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },
    getAllLists() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.startFrom = this.start_date
        ? this.start_date
        : "1992-11-05 00:00:00.000";
      filters.startTo = this.end_date
        ? this.end_date
        : "2992-11-05 00:00:00.000";
      //  filters.method = 1;
      let dispatch = this.$store.dispatch("moduleList/getAllList", filters);
      dispatch
        .then((response) => {
          vm.lists = this.$store.getters["moduleList/getAllList"];

          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
        });
    },

    getAllLessons() {
      let vm = this;
      let course_id = this.selectedList ? this.selectedList.id : "";
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.type = "both"; //1 or 0 or both'
      filters.course_id = course_id;

      let dispatch = this.$store.dispatch(
        "moduleCourse/getAllCourses",
        filters
      );
      dispatch
        .then((response) => {
          vm.lessons = this.$store.getters["moduleCourse/getAllCourses"];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },

    getAllDisscussion() {
      let vm = this;
      if(!vm.selectedLesson) return;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();
      filters.lessonId = vm.selectedLesson?vm.selectedLesson.id:'';
      // delete filters.searchKey;
      let payload = {};
      payload.filters = filters;
      let dispatch = this.$store.dispatch(
        // "moduleLessonDiscussion/getAllLessonDiscussion",
        "moduleLessonDiscussion/getAllDisscusion",
        payload
      );
      dispatch
        .then((response) => {
          vm.discussions = this.$store.getters[
            "moduleLessonDiscussion/getAllLessonDiscussion"
          ];
          vm.$helper.hideLoader();
        })
        .catch((error) => {
          vm.$helper.hideLoader();
          vm.$helper.handleError(error, vm);
         });
    },
    submitForm(lessonDiscussionId, index) {
      this.$validator.validateAll("comment_" + index).then((result) => {
        if (result) {
          // if form have no errors
          this.addComment(lessonDiscussionId, index);
        } else {
          // form have validation errors
          $("#comment_" + index).text(this.$t("isRequired"));
        }
      });
    },
    addComment(lessonDiscussionId, index) {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      payload.data = {};
      if (!lessonDiscussionId) return;

      payload.data.text = vm.comment[index];
      payload.data.userId = vm.$helper.getLocalStorage("userInfo")
        ? vm.$helper.getLocalStorage("userInfo").id
        : "";
      //   payload.data.lessonId = vm.selectedLesson?vm.selectedLesson.id:1;
      payload.data.lessonDiscussionId = lessonDiscussionId;

      vm.$store
        .dispatch("moduleLessonDiscussion/addLessonDiscussion", payload)
        .then((response) => {
            vm.comment[index]='';
            let comment=response.data.data.comment.comment;
            comment.updatedAt=new moment();
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
        
        //   let length=vm.discussions[index].lessonDiscussionComments.length;
        //   this.$set(vm.discussions[index].lessonDiscussionComments,length,comment);
       
         //prepare data to the array
        let payload={};
         payload.index=index;
         payload.comment=comment;
          //push data to the array
        this.$store.commit('moduleLessonDiscussion/ADD_LESSON_DISSCUSSION_COMMENT', payload);

        })
        .catch((error) => {
          vm.$helper.hideLoader();
           vm.$helper.handleError(error, vm);
         });
    },
    
    addDiscussion() {
      let vm = this;
      vm.$helper.showLoader();
      const payload = {};
      payload.data = {};
 
      payload.data.text = vm.post;
      payload.data.userId = vm.$helper.getLocalStorage("userInfo")
        ? vm.$helper.getLocalStorage("userInfo").id
        : "";
        //MMostaf
        payload.data.lessonId = vm.selectedLesson?vm.selectedLesson.id:'';
 
      vm.$store
        .dispatch("moduleLessonDiscussion/addLessonDiscussion", payload)
        .then((response) => {
            vm.post='';
            let discussion=response.data.data.post.post;
            discussion.lessonDiscussionComments=[];
            discussion.updatedAt=new moment();
           vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
          vm.closeModalPost();
          //push data to the array
        this.$store.commit('moduleLessonDiscussion/ADD_LESSON_DISSCUSSION', discussion);

        })
        .catch((error) => {
          vm.$helper.hideLoader();
           vm.$helper.handleError(error, vm);
         });
    },
   
    deleteComment(lessonDiscussionId,index,cindex){
         let vm = this;
       
       
      vm.$helper.showLoader();
      const payload = {};
       if (!lessonDiscussionId) return;

      payload.id = lessonDiscussionId;

      vm.$store
        .dispatch("moduleLessonDiscussion/removeLessonDiscussionComments", payload)
        .then(() => {
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
 
       //prepare data to the array
         let payload={};
         payload.index=index;
         payload.cindex=cindex;
         //delete data from the array
          this.$store.commit('moduleLessonDiscussion/REMOVE_LESSON_DISSCUSSION_COMMENT', payload);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
           vm.$helper.handleError(error, vm);
         });
    },
    deleteDiscussion(DiscussionId,index){
         let vm = this;
       
       
      vm.$helper.showLoader();
      const payload = {};
       if (!DiscussionId) return;

      payload.id = DiscussionId;

      vm.$store
        .dispatch("moduleLessonDiscussion/removeLessonDiscussion", payload)
        .then(() => {
          vm.$helper.hideLoader();
          vm.$helper.showMessage("success", vm);
         
         //delete data from the array
          this.$store.commit('moduleLessonDiscussion/REMOVE_LESSON_DISSCUSSION', index);
        })
        .catch((error) => {
          vm.$helper.hideLoader();
           vm.$helper.handleError(error, vm);
         });
    },


  },
};
</script>

<style scoped>
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css";
.post-content {
  background: #f8f8f8;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #f1f2f2;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.post-content img.post-image,
video.post-video,
.google-maps {
  width: 100%;
  height: auto;
}

.post-content .google-maps .map {
  height: 300px;
}

.post-content .post-container {
  padding: 20px;
}

.post-content .post-container .post-detail {
  margin-left: 65px;
  position: relative;
}

.post-content .post-container .post-detail .post-text {
  line-height: 24px;
  margin: 0;
}

.post-content .post-container .post-detail .reaction {
  position: absolute;
  right: 0;
  top: 0;
}

.post-content .post-container .post-detail .post-comment {
  display: inline-flex;
  margin: 10px auto;
  width: 100%;
}

.post-content .post-container .post-detail .post-comment img.profile-photo-sm {
  margin-right: 10px;
}

.post-content .post-container .post-detail .post-comment .form-control {
  height: 30px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 7px 0;
  min-width: 0;
}

img.profile-photo-md {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  float: right;
  margin-left: 20px;
}

img.profile-photo-sm {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.text-green {
  color: #8dc63f;
}

.text-red {
  color: #ef4136;
}

.following {
  color: #8dc63f;
  font-size: 12px;
  margin-left: 20px;
}
</style>